import React, { useEffect, useRef, useState } from 'react';
import { GoPlus } from "react-icons/go";
import styles from './location.module.css';
import success from '../../assests/Success.png';
import { baseurl } from '../../config/Base_url';
import axios from 'axios';
import LocationHead from '../../components/locationhead/LocationHead';
import LocationList from '../../components/locationlist/locationList';
import { Modal, Button, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

const Location = () => {

    const [searchTerm, setSearchTerm] = useState('');
    const [searchEmployee, setSearchEmployee] = useState("")
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [emplyoee, setEmplyoee] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState("")
    const [statusOfPayment, setStatusOfPayment] = useState(true); 

    useEffect(() => {
        fetchData();
        fetchLocationData();
    }, [searchTerm, statusOfPayment]);

    const [locationList, setLocationList] = useState([]);

    const handleButtonClick = (value) => {
        setStatusOfPayment(value);
    };


    const fetchLocationData = async () => {
        try {
            let response = await axios.get(`${baseurl}/api/location/${userDetail._id}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    searchQuery: searchTerm,
                    statusofpayment: statusOfPayment.toString(),
                },
            });
            setLocationList(response.data.locations);
        } catch (error) {
            console.error('Error fetching locations:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    const fetchData = async () => {
        try {
            let response;
            if (searchTerm) {
                response = await axios.get(`${baseurl}/api/employee/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                    params: { searchEmployee: searchEmployee }
                });
            } else {
                response = await axios.get(`${baseurl}/api/employee/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
            }
            setEmplyoee(response.data.employees);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});

    const setField = (field, value) => {

        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    const typeaheadRef = useRef(null);


    const handleModalClose = () => {
        setField('locationname', '');
        setField('address', '');
        setField('percentage', '');
        setField('employeeSelection', []);
        setErrors({});
        setSelectedEmployees([]);
        setIsFormInvalid(true);
        setSearchTerm('');
        setSearchEmployee('');

        if (typeaheadRef.current) {
            typeaheadRef.current.clear();
        }
    };
    const validateForm = () => {
        const { locationname, address, percentage, employeeSelection } = form;
        const newErrors = {};

        if (!locationname || locationname === "") newErrors.locationname = "Please Enter Location";
        if (!address || address === "") newErrors.address = "Please Enter Location Address";
        if (!percentage || percentage === "") {
            newErrors.percentage = "Please Enter Percentage";
        } else {
            const parsedPercentage = parseFloat(percentage);
            if (isNaN(parsedPercentage) || parsedPercentage < 0 || parsedPercentage > 100) {
                newErrors.percentage = "Percentage must be a number between 0 and 100";
            }
        }
        if (!employeeSelection || employeeSelection?.length === 0) newErrors.employeeSelection = "Please Select at least one Employee";

        return newErrors;
    };

    const storedToken = localStorage.getItem('authToken');
    const userDetail = JSON.parse(localStorage.getItem('user'));

    const handleCreateLocation = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            try {
                const storedToken = localStorage.getItem('authToken');
                const response = await axios.post(`${baseurl}/api/location/create`, {
                    locationname: form.locationname,
                    address: form.address,
                    percentage: form.percentage,
                    employeeIds: form.employeeSelection?.map(employee => employee.value), // Mapping selected employees to their IDs
                }, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });

                if (response && response.data && response.data.success) {
                    setShowSuccessModal(true);
                    setShowErrorModal(false);
                    fetchLocationData()
                    handleModalClose();
                }
            } catch (error) {
                console.error('Error creating location:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                    setShowErrorModal(true);
                    setShowSuccessModal(false);
                } else {
                    setErrorMsg('An error occurred. Please try again.');
                    setShowErrorModal(true);
                    setShowSuccessModal(false);
                }
            }
        }
    };

    useEffect(() => {
        const formErrors = validateForm();
        const isAnyFieldEmpty = Object.keys(form).some((key) => !form[key]);
        const hasErrors = Object.keys(formErrors).some((key) => !!formErrors[key]);

        setIsFormInvalid(isAnyFieldEmpty || hasErrors);
    }, [form]);

    const options = emplyoee?.map((item) => ({
        value: item._id,
        label: item.firstname
    }))

    const handleEmployeeSelection = (selectedOptions) => {
        setSelectedEmployees(selectedOptions);
        setSearchEmployee(selectedOptions.map(option => option.value));
        setField("employeeSelection", selectedOptions);
    };

    return (
        <div className={`px-3 h-100 rounded-3`}>
            <div className={`${styles.displayhead}`}>
                <div className={`d-flex justify-content-between align-items-center  ${styles.displaysearch}`}>
                    <h3 className='me-5 fw-bold'>Location</h3>
                    <div className={`input-group w-auto ${styles.searchinput}`}>
                        <div className={`d-flex ${styles.searchdisplay}`}>
                            <div className={`d-flex ${styles.search}`}>
                                <input
                                    type="search"
                                    className={`form-control border-0 bg-white border-0 shadow-none ${styles.searchfield}`}
                                    placeholder="Search"
                                    aria-label="Recipient's username with two button addons"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />

                                <button className={`me-2 fs-5 ${styles.searchbtn}`} type="button"><span className='me-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <g opacity="0.6">
                                            <path d="M6.14286 11.2857C8.98318 11.2857 11.2857 8.98318 11.2857 6.14286C11.2857 3.30254 8.98318 1 6.14286 1C3.30254 1 1 3.30254 1 6.14286C1 8.98318 3.30254 11.2857 6.14286 11.2857Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13 13L10.4286 10.4286" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                    </svg>
                                </span></button>
                            </div>
                            <button className={`${styles.addbtn}`} data-bs-toggle="modal" href='#addlocaionmodal' type="button"><GoPlus className='fs-4' />Add New Location</button>
                        </div>
                    </div>
                </div>
                <div className={`btn-group ${styles.paidunpaidbtn} mt-3 ms-2`} role="group" aria-label="Basic radio toggle button group">
                    <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autoComplete="off"
                        checked={statusOfPayment}
                        onChange={() => handleButtonClick(true)}
                    />
                    <label className={`btn ${styles.customRadio}`} htmlFor="btnradio1">
                        Paid
                    </label>

                    <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autoComplete="off"
                        checked={!statusOfPayment}
                        onChange={() => handleButtonClick(false)}
                    />
                    <label className={`btn ${styles.customRadio}`} htmlFor="btnradio2">
                        UnPaid
                    </label>
                </div>
            </div>
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <img src={success} alt="success" />
                        <p className="fw-bold">You have successfully Created a New Location!</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className={styles.closebtn} onClick={() => setShowSuccessModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className={styles.closebtn} onClick={() => setShowErrorModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className={`${styles.location} h-100`}>
                <div className={`${styles.divWidth} h-100`}>
                    <LocationHead />
                    <div className={`${styles.locationlist}`}>
                        <LocationList searchTerm={searchTerm} locations={locationList} />
                    </div>
                </div>
            </div>
            <div className="modal fade" id="addlocaionmodal" aria-hidden="true" aria-labelledby="addlocaionmodal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addlocaionmodal">Create Location</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" >
                            <div className='row'>
                                <div className='col-12'>
                                    <Form>
                                        <Form.Group controlId='locationname' className={`${styles.formgroup}`}>
                                            <Form.Label className={`${styles.label}`}>Location Name<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder='Enter location name here'
                                                value={form.locationname}
                                                onChange={(e) => setField("locationname", e.target.value)}
                                                isInvalid={!!errors.locationname}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.locationname && <div className="text-danger">{errors.locationname}</div>}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId='address' className={`${styles.formgroup}`}>
                                            <Form.Label className={`${styles.label}`}>Location Address<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder='Enter location address here'
                                                value={form.address}
                                                onChange={(e) => setField("address", e.target.value)}
                                                isInvalid={!!errors.address}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.address && <div className="text-danger">{errors.address}</div>}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId='employeeSelection' className={`${styles.formgroup}`}>
                                            <Form.Label className={`${styles.label}`}>Select Employee<span className='text-danger '>*</span></Form.Label>
                                            <Typeahead
                                                ref={typeaheadRef}
                                                id="basic-typeahead-multiple"
                                                className={`${styles.selectbox}`}
                                                multiple
                                                options={options}
                                                placeholder="Select employees"
                                                value={selectedEmployees}
                                                onChange={handleEmployeeSelection}
                                                isInvalid={!!errors.employeeSelection}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.employeeSelection && <div className="text-danger">{errors.employeeSelection}</div>}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId='percentage'>
                                            <Form.Label className={`${styles.label}`}>Percentage<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder='Enter percentage here'
                                                value={form.percentage}
                                                onChange={(e) => setField("percentage", e.target.value)}
                                                isInvalid={!!errors.percentage}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.percentage && <div className="text-danger">{errors.percentage}</div>}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="modal-footer">
                                            <button type="button"
                                                className={`${styles.closebtn}`}
                                                onClick={handleModalClose}
                                                data-bs-dismiss="modal"
                                            >
                                                Close</button>
                                            <Button
                                                type="submit"
                                                className={`${styles.createbtn} `}
                                                data-bs-dismiss={isFormInvalid ? "" : "modal"}
                                                onClick={(e) => {
                                                    handleCreateLocation(e);
                                                }}
                                            >
                                                Create
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Location