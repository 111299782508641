import React from 'react';
import styles from './sarecentcollectionlist.module.css';


const SaRecentCollectionList = () => {
    return (
        <div className={`row ${styles.collectionlist}`}>
            <div className="col-2">
                <p>#1-876364</p>
            </div>
            <div className="col-2">
                <p>Moonlight Bar</p>
            </div>
            <div className="col-2">
                <p>7</p>
            </div>
            <div className="col-2">
                <p>$2500</p>
            </div>
            <div className="col-2">
                <p>$2000</p>
            </div>
            <div className="col-2">
                <p>$500</p>

            </div>
        </div>
    )
}

export default SaRecentCollectionList
