import React, { useEffect, useState } from 'react'
import style from './repairlist.module.css'
import ReactPaginate from 'react-paginate';
import { IconContext } from 'react-icons';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { Form } from 'react-bootstrap';
import Loading from '../loading/Loading';
import axios from 'axios';
import { baseurl } from '../../config/Base_url';


const RepairList = ({ searchTerm, repairs: propRepairs }) => {

    const [repairs, setRepairs] = useState([])
    const [loading, setLoading] = useState()
    const storedToken = localStorage.getItem('authToken');
    const userDetail = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        setRepairs(propRepairs);
    }, [propRepairs]);

    //pagination
    const [page, setPage] = useState(0);
    const [filterData, setFilterData] = useState();
    const [n, setN] = useState(9);

    const perPageOptions = [
        { value: 9, label: '9' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
    ];

    useEffect(() => {
        setFilterData(
            repairs?.filter((item, index) => {
                return (index >= page * n) & (index < (page + 1) * n);
            })
        );
    }, [page, n, repairs]);

    const handlePageChange = (selectedPage) => {
        setPage(selectedPage.selected);
    };

    const handlePerPageChange = (event) => {
        setN(Number(event.target.value));
    };

    useEffect(() => {
        fetchRepairData();
    }, []);

    const fetchRepairData = async () => {
        try {
            let response;
            if (searchTerm) {
                setLoading(true)
                response = await axios.get(`${baseurl}/api/user/repairs/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                    params: { search: searchTerm }
                });
                setLoading(false)
            } else {
                setLoading(true)
                response = await axios.get(`${baseurl}/api/user/repairs/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
                setLoading(false)
            }
            setRepairs(response.data.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <div>
                    {filterData?.length === 0 ? (
                        <div className='text-center'>No data found</div>
                    ) : (
                        filterData && filterData.map((item) => (
                            item.machines.map((machine) => (
                                <div className='mt-3' key={machine._id}>
                                    <div className={`${style.repairlist} row px-5 align-items-center rounded-3 bg-white`}>
                                        <div className="col-2">
                                            <div>
                                                <p className='fw-bold mb-0'>{machine.machineNumber}-{machine.serialNumber}</p>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div>
                                                <p className='fw-bold mb-0'>{item.location.locationname}</p>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div>
                                                <p className='fw-bold mb-0'>{machine.reporterName}</p>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div>
                                                <p className='fw-bold mb-0'>{machine.issue}</p>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div>
                                                <p className='fw-bold mb-0'>
                                                    {new Intl.DateTimeFormat('en-US', {
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                        year: 'numeric',
                                                    }).format(new Date(machine.date))}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div>
                                                <p className='fw-bold mb-0'>{machine.time}</p>
                                            </div>
                                        </div>
                                        <div className="col-1 d-flex align-items-center justify-content-between">
                                            <div>
                                                <button className={`${style.statusbtn} ${machine.statusOfRepair === 'Running' ? style.closedStatus :
                                                        machine.statusOfRepair === 'Pending' ? style.pendingStatus :
                                                        machine.statusOfRepair === 'Active' ? style.activeStatus : ''
                                                    }`}>
                                                    {machine.statusOfRepair}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ))
                    )}
                </div>
            )}

            {
                repairs.length > 9 && (
                    <div className="d-flex justify-content-end">
                        <div className='d-flex align-items-center me-3'>
                            <div className='me-1'>
                                Rows Per Page:
                            </div>
                            <div>
                                <Form.Select size="sm" value={n} onChange={handlePerPageChange}>
                                    {perPageOptions.map((option, idx) => (
                                        <option key={idx} value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <ReactPaginate
                            containerClassName={"pagination align-items-center mb-0"}
                            activeClassName={`${style.pagination}`}
                            pageClassName={"page-item p-2 px-3"}
                            onPageChange={handlePageChange}
                            breakLabel={<span className="break-label">...</span>}
                            pageCount={Math.ceil(repairs?.length / n)}
                            previousLabel={
                                <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                                    <AiFillLeftCircle />
                                </IconContext.Provider>
                            }
                            nextLabel={
                                <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                                    <AiFillRightCircle />
                                </IconContext.Provider>
                            }
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                        />
                    </div>
                )
            }
        </>
    )
}

export default RepairList
