import React from 'react'
import { Link } from 'react-router-dom'
import style from './employeehead.module.css'

const EmployeeHead = () => {
    return (
        <>

            <div className={`${style.mainhead} row px-5`}>
                <div className='col-3 col-lg-3 col-md-3'>
                    <div className="dropdown">
                        <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                            Employee Name
                        </button>
                    </div>
                </div>
                <div className='col-2 col-lg-2 col-md-2'>
                    <div className='d-flex align-items-center'>
                        <div className="dropdown">
                            <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                Email Address
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-2 col-lg-2 col-md-2'>
                    <div className='d-flex align-items-center'>
                        <div className="dropdown">
                            <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                Mobile Number
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-2 col-lg-2 col-md-3'>
                    <div className='d-flex align-items-center'>
                        <div className="dropdown">
                            <button className={`btn btn-transparent ${style.dropbtn}`} type="button" >
                                Address
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-3 col-lg-3 col-md-2'>
                    <div className='d-flex align-items-center'>
                        <div className="dropdown">
                            <button className={`btn btn-transparent ${style.dropbtn}`} type="button" >
                                Status
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeHead
