import React from 'react'
import { Link } from 'react-router-dom'
import style from './servicereporthead.module.css'

const ServiceReportHead = () => {


    return (
        <>
            <div className={` ${style.servicereporthead} pb-4`}>
                <div className='row rounded-2 px-5'>
                    <div className='col-3'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                    Serial No
                                </button>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                  Employee
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                    Issue
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                 Date
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceReportHead
