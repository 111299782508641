import React, { useState } from 'react'
import { IoSearch } from "react-icons/io5";
import styles from './report.module.css'
import RecentCollection from '../../components/recentcollection/RecentCollection';
import LineChart from '../../components/reportchart/LineChart';

const Report = () => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className={`rounded-3 px-3  ${styles.mainreport}`}>
      <div className={`d-flex justify-content-between align-items-center ${styles.reporthead}`}>
        <h3 className='me-5 fw-bold'>Report</h3>
        <div className={`input-group  ${styles.searchinput}`}>

          <input
            type="search"
            className={`form-control border-0 bg-white h-100 ${styles.searchfield}`}
            placeholder="Search"
            aria-label="Recipient's username with two button addons"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className={`btn bg-white btn-outline-secondary me-2 border-0 fs-5 ${styles.searchbtn}`} type="button"><IoSearch className='fs-5' /></button>
        </div>
      </div>
      <div className={`${styles.reportdata}`}>
        <div className={`${styles.chart}`}>
          <div className={`${styles.margin} pt-4`}>
            <div className={`d-flex justify-content-between ${styles.reportmenu}`}>
              <div>
                <h1 className={`${styles.headingFont} ps-4`}>Reports</h1>
              </div>
              <div >
                <ul className={`${styles.btnpadding} pe-4 nav nav-pills mb-3`} id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className={`${styles.btn} nav-link active `} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Mounthly</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`${styles.btn} nav-link`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Quartly</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`${styles.btn} nav-link  `} id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Yearly</button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div className={`${styles.linechart}`}>
                  <LineChart />
                </div>
              </div>
              <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <LineChart />
              </div>
              <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <LineChart />
              </div>
            </div>
          </div>

        </div>
        <div className='row'>
          <div className="col-xl-7 col-md-12">
            <RecentCollection />
          </div>
          <div className="col-xl-5">

          </div>
        </div>
      </div>
    </div>
  )
}

export default Report