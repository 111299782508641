import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styles from './loading.module.css'

const Loading = () => {
  return (
    <div className={styles.loadingContainer}>
      <Spinner animation="border" role="status" className={styles.loadingSpinner}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  )
}

export default Loading
