import React, { useEffect, useState } from 'react';
import styles from './sadashboarddata.module.css'
import { baseurl } from '../../config/Base_url';
import axios from 'axios';

const SaDashboardData = () => {

    const [employee, setEmplyoee] = useState([])
    const [adminList, setAdminList] = useState([])
    const [location, setLocation] = useState()

    const storedToken = localStorage.getItem('authToken');
    useEffect(() => {
        fetchData();
        fetchAdminData();
        fetchLocation();
    }, [])
    const fetchData = async () => {
        try {
            let response;
            response = await axios.get(`${baseurl}/api/employee/allemployee`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            setEmplyoee(response.data.employees);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchLocation = async () => {
        try {
            let response;
            response = await axios.get(`${baseurl}/api/location`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            setLocation(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }

    const fetchAdminData = async () => {
        try {
            let response;
            response = await axios.get(`${baseurl}/api/user/alluser`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            setAdminList(response.data.users);
        }
        catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    return (
        <div>
            <div className={`row mt-4 mb-4 gap-4 ${styles.dashboardmain} dashboardmain`}>
                <div className={`col-3 justify-content-center ${styles.dashboardData}`}>
                    <div className={`d-flex ps-5 align-items-center gap-4 w-100 ${styles.backgroundColorWhite}`}>
                        <div className={`${styles.bglocation}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M15 6V21M15 6L21 3V18L15 21M15 6L9 3M15 21L9 18M9 18L3 21V6L9 3M9 18V3" stroke="#5B93FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div>
                            <h3>{location?.totalLocations}</h3>
                            <p>Locations</p>
                        </div>
                    </div>
                </div>
                <div className={`col-3 justify-content-center ${styles.dashboardData} `}>
                    <div className={`d-flex ps-5 align-items-center gap-4 w-100 ${styles.backgroundColorWhite}`}>
                        <div className={`${styles.bgemployee}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M17 20C17 18.3431 14.7614 17 12 17C9.23858 17 7 18.3431 7 20M21 17.0004C21 15.7702 19.7659 14.7129 18 14.25M3 17.0004C3 15.7702 4.2341 14.7129 6 14.25M18 10.2361C18.6137 9.68679 19 8.8885 19 8C19 6.34315 17.6569 5 16 5C15.2316 5 14.5308 5.28885 14 5.76389M6 10.2361C5.38625 9.68679 5 8.8885 5 8C5 6.34315 6.34315 5 8 5C8.76835 5 9.46924 5.28885 10 5.76389M12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z" stroke="#5B93FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div>
                            <h3>{adminList.length}</h3>
                            <p>Admins</p>
                        </div>
                    </div>
                </div>
                <div className={`col-3 justify-content-center ${styles.dashboardData} `}>
                    <div className={`d-flex ps-5 align-items-center gap-2 w-100 ${styles.backgroundColorWhite}`}>
                        <div className={`${styles.bgemployee}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M17 20C17 18.3431 14.7614 17 12 17C9.23858 17 7 18.3431 7 20M21 17.0004C21 15.7702 19.7659 14.7129 18 14.25M3 17.0004C3 15.7702 4.2341 14.7129 6 14.25M18 10.2361C18.6137 9.68679 19 8.8885 19 8C19 6.34315 17.6569 5 16 5C15.2316 5 14.5308 5.28885 14 5.76389M6 10.2361C5.38625 9.68679 5 8.8885 5 8C5 6.34315 6.34315 5 8 5C8.76835 5 9.46924 5.28885 10 5.76389M12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z" stroke="#5B93FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div>
                            <h3>{employee.length}</h3>
                            <p>Emplyoees</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SaDashboardData
