import React, { useState } from 'react'
import styles from './sasidebar.module.css';
import { IoIosMenu } from "react-icons/io";
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../assests/Union.png'
import profile from '../../assests/profile.png'

const SaSidebar = () => {
    const navigate = useNavigate()
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const userDetail = JSON.parse(localStorage.getItem('user'))
    const handleLogout = () => {
        localStorage.clear()
        navigate('/')
    }

    return (
        <>
            <div className={`${styles.open} mt-4`}>
                <div className='d-flex flex-column ' >
                    <div className={`d-flex mb-5 align-items-center justify-content-center gap-4 ${styles.marginlogo}`} onClick={toggleSidebar}>
                        <img src={logo} alt="" className='image-fluid' />

                        <h2 className={` ${styles.sidebarMenu} ${styles.headingroute}`}>Route <br /> Runner</h2>
                    </div>
                    <div className='text-center'> <button
                        className={`btn d-lg-none d-md-none ${styles.togglebtn}`}
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvas"
                        type="button"
                    >
                        <IoIosMenu />
                    </button></div>
                    <ul className={`list-unstyled ${styles.togglemenu}`}>
                        <li>
                            <div className={`d-flex gap-3 align-items-center mb-2  `}>
                                <NavLink to={"/superadmin/home"} className={({ isActive }) =>
                                    isActive ? `text-decoration-none w-100 text-secondary p-2 ${styles.bggradient}` : `text-decoration-none w-100 text-secondary p-2 ${styles.navlinkpadding} `
                                }>
                                    <div className={`d-flex  ${styles.startpadding}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                            <path d="M20 17.0002V11.4522C20 10.9179 19.9995 10.6506 19.9346 10.4019C19.877 10.1816 19.7825 9.97307 19.6546 9.78464C19.5102 9.57201 19.3096 9.39569 18.9074 9.04383L14.1074 4.84383C13.3608 4.19054 12.9875 3.86406 12.5674 3.73982C12.1972 3.63035 11.8026 3.63035 11.4324 3.73982C11.0126 3.86397 10.6398 4.19014 9.89436 4.84244L5.09277 9.04383C4.69064 9.39569 4.49004 9.57201 4.3457 9.78464C4.21779 9.97307 4.12255 10.1816 4.06497 10.4019C4 10.6506 4 10.9179 4 11.4522V17.0002C4 17.932 4 18.3978 4.15224 18.7654C4.35523 19.2554 4.74432 19.6452 5.23438 19.8482C5.60192 20.0005 6.06786 20.0005 6.99974 20.0005C7.93163 20.0005 8.39808 20.0005 8.76562 19.8482C9.25568 19.6452 9.64467 19.2555 9.84766 18.7654C9.9999 18.3979 10 17.932 10 17.0001V16.0001C10 14.8955 10.8954 14.0001 12 14.0001C13.1046 14.0001 14 14.8955 14 16.0001V17.0001C14 17.932 14 18.3979 14.1522 18.7654C14.3552 19.2555 14.7443 19.6452 15.2344 19.8482C15.6019 20.0005 16.0679 20.0005 16.9997 20.0005C17.9316 20.0005 18.3981 20.0005 18.7656 19.8482C19.2557 19.6452 19.6447 19.2554 19.8477 18.7654C19.9999 18.3978 20 17.932 20 17.0002Z"
                                                stroke="#6c757d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className={`ms-3 my-2 ${styles.sidebarMenu}`}>Dashboard</p>
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className={`d-flex gap-3 align-items-center mb-2`}>
                                <NavLink to={"/superadmin/admin"} className={({ isActive }) =>
                                    isActive ? `text-decoration-none w-100 text-secondary p-2 ${styles.bggradient}` : `text-decoration-none w-100 text-secondary p-2 ${styles.navlinkpadding} `
                                }>
                                    <div className={`d-flex  ${styles.startpadding}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                            <path d="M21 19.9999C21 18.2583 19.3304 16.7767 17 16.2275M15 20C15 17.7909 12.3137 16 9 16C5.68629 16 3 17.7909 3 20M15 13C17.2091 13 19 11.2091 19 9C19 6.79086 17.2091 5 15 5M9 13C6.79086 13 5 11.2091 5 9C5 6.79086 6.79086 5 9 5C11.2091 5 13 6.79086 13 9C13 11.2091 11.2091 13 9 13Z" stroke="#6c757d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className={`ms-3 my-2 ${styles.sidebarMenu}`}>Admin</p>
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className={`d-flex gap-3 align-items-center mb-2  `}>
                                <NavLink to={"/superadmin/employee"} className={({ isActive }) =>
                                    isActive ? `text-decoration-none w-100 text-secondary p-2 ${styles.bggradient}` : `text-decoration-none w-100 text-secondary p-2 ${styles.navlinkpadding} `
                                }>
                                    <div className={`d-flex  ${styles.startpadding}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                            <path d="M17 20C17 18.3431 14.7614 17 12 17C9.23858 17 7 18.3431 7 20M21 17.0004C21 15.7702 19.7659 14.7129 18 14.25M3 17.0004C3 15.7702 4.2341 14.7129 6 14.25M18 10.2361C18.6137 9.68679 19 8.8885 19 8C19 6.34315 17.6569 5 16 5C15.2316 5 14.5308 5.28885 14 5.76389M6 10.2361C5.38625 9.68679 5 8.8885 5 8C5 6.34315 6.34315 5 8 5C8.76835 5 9.46924 5.28885 10 5.76389M12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z"
                                                stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className={`ms-3 my-2 ${styles.sidebarMenu}`}>Employee</p>
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                        <div className={`d-flex gap-3 align-items-center justify-content-center ${styles.userinfo}`}>
                            <img src={profile} alt="" style={{ width: "50px", height: "50px" }} className='rounded-3' />
                            <div>
                                <p className='mb-0'>{userDetail.firstname}</p>
                                <p className='mb-0 text-secondary'>Super Admin</p>
                            </div>
                            <button className='bg-transparent border-0' onClick={handleLogout}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <g opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.24596 8.35841C6.88138 8.35841 6.5931 8.64175 6.5931 9.00008C6.5931 9.35008 6.88138 9.64175 7.24596 9.64175H12.3332V13.6251C12.3332 15.6667 10.6459 17.3334 8.56016 17.3334H4.43104C2.35376 17.3334 0.666504 15.6751 0.666504 13.6334V4.37508C0.666504 2.32508 2.36224 0.666748 4.43952 0.666748H8.57712C10.6459 0.666748 12.3332 2.32508 12.3332 4.36675V8.35841H7.24596ZM15.3584 6.11692L17.7917 8.54192C17.9167 8.66692 17.9834 8.82525 17.9834 9.00025C17.9834 9.16692 17.9167 9.33359 17.7917 9.45025L15.3584 11.8753C15.2334 12.0003 15.0667 12.0669 14.9084 12.0669C14.7417 12.0669 14.575 12.0003 14.45 11.8753C14.2 11.6253 14.2 11.2169 14.45 10.9669L15.7834 9.64192H12.3334V8.35859H15.7834L14.45 7.03359C14.2 6.78359 14.2 6.37525 14.45 6.12525C14.7 5.86692 15.1084 5.86692 15.3584 6.11692Z" fill="#030229" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </ul >
                </div >
            </div >
            <div className={`offcanvas overflow-auto offcanvas-start  ${styles.sideBarDiv}`} tabIndex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="true">
                <div className={`offcanvas-header ${styles.headingmenu}`}>
                    <div className='d-flex align-items-center gap-4 justify-content-center'>
                        <img src={logo} alt="" className='img-fluid' />
                        <h2 className={`${styles.fontSizeRouter}`}>Route <br /> Runner</h2>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                </div>
                <div>
                    <div className='d-flex flex-column'  >
                        <ul className={`list-unstyled mt-3 `}>
                            <li>
                                <div className={`d-flex gap-3 align-items-center mb-2 `}>
                                    <NavLink to={"/superadmin/home"} className={({ isActive }) =>
                                        isActive ? `text-decoration-none w-100 text-secondary p-2 ${styles.bggradient}` : `text-decoration-none w-100 text-secondary p-2 ${styles.navlinkpadding} `
                                    }>
                                        <div className={`${styles.menu} d-flex  `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M20 17.0002V11.4522C20 10.9179 19.9995 10.6506 19.9346 10.4019C19.877 10.1816 19.7825 9.97307 19.6546 9.78464C19.5102 9.57201 19.3096 9.39569 18.9074 9.04383L14.1074 4.84383C13.3608 4.19054 12.9875 3.86406 12.5674 3.73982C12.1972 3.63035 11.8026 3.63035 11.4324 3.73982C11.0126 3.86397 10.6398 4.19014 9.89436 4.84244L5.09277 9.04383C4.69064 9.39569 4.49004 9.57201 4.3457 9.78464C4.21779 9.97307 4.12255 10.1816 4.06497 10.4019C4 10.6506 4 10.9179 4 11.4522V17.0002C4 17.932 4 18.3978 4.15224 18.7654C4.35523 19.2554 4.74432 19.6452 5.23438 19.8482C5.60192 20.0005 6.06786 20.0005 6.99974 20.0005C7.93163 20.0005 8.39808 20.0005 8.76562 19.8482C9.25568 19.6452 9.64467 19.2555 9.84766 18.7654C9.9999 18.3979 10 17.932 10 17.0001V16.0001C10 14.8955 10.8954 14.0001 12 14.0001C13.1046 14.0001 14 14.8955 14 16.0001V17.0001C14 17.932 14 18.3979 14.1522 18.7654C14.3552 19.2555 14.7443 19.6452 15.2344 19.8482C15.6019 20.0005 16.0679 20.0005 16.9997 20.0005C17.9316 20.0005 18.3981 20.0005 18.7656 19.8482C19.2557 19.6452 19.6447 19.2554 19.8477 18.7654C19.9999 18.3978 20 17.932 20 17.0002Z"
                                                    stroke="#6c757d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='ms-3 mb-0'>Dashboard</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex gap-3 align-items-center mb-2 '>
                                    <NavLink to={"/superadmin/admin"} className={({ isActive }) =>
                                        isActive ? `text-decoration-none w-100 text-secondary p-2 ${styles.bggradient}` : `text-decoration-none w-100 text-secondary p-2 ${styles.navlinkpadding} `
                                    }>
                                        <div className={`${styles.menu} d-flex `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                                <path d="M21 19.9999C21 18.2583 19.3304 16.7767 17 16.2275M15 20C15 17.7909 12.3137 16 9 16C5.68629 16 3 17.7909 3 20M15 13C17.2091 13 19 11.2091 19 9C19 6.79086 17.2091 5 15 5M9 13C6.79086 13 5 11.2091 5 9C5 6.79086 6.79086 5 9 5C11.2091 5 13 6.79086 13 9C13 11.2091 11.2091 13 9 13Z" stroke="#6c757d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='ms-3 mb-0'>Admin</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex gap-3 align-items-center mb-2'>
                                    <NavLink to={"/superadmin/employee"} className={({ isActive }) =>
                                        isActive ? `text-decoration-none w-100 text-secondary p-2 ${styles.bggradient}` : `text-decoration-none w-100 text-secondary p-2 ${styles.navlinkpadding} `
                                    }>
                                        <div className={`${styles.menu} d-flex  `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M17 20C17 18.3431 14.7614 17 12 17C9.23858 17 7 18.3431 7 20M21 17.0004C21 15.7702 19.7659 14.7129 18 14.25M3 17.0004C3 15.7702 4.2341 14.7129 6 14.25M18 10.2361C18.6137 9.68679 19 8.8885 19 8C19 6.34315 17.6569 5 16 5C15.2316 5 14.5308 5.28885 14 5.76389M6 10.2361C5.38625 9.68679 5 8.8885 5 8C5 6.34315 6.34315 5 8 5C8.76835 5 9.46924 5.28885 10 5.76389M12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z"
                                                    stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='ms-3 mb-0'>Employee</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <div className={`d-flex gap-3 align-items-center ${styles.userinfo}`}>
                                <img src={profile} alt="" style={{ width: "50px", height: "50px" }} className='rounded-3' />
                                <div>
                                    <p className='mb-0'>User name </p>
                                    <p className='mb-0 text-secondary'>Super Admin</p>
                                </div>
                                <button className='bg-transparent border-0'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <g opacity="0.4">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.24596 8.35841C6.88138 8.35841 6.5931 8.64175 6.5931 9.00008C6.5931 9.35008 6.88138 9.64175 7.24596 9.64175H12.3332V13.6251C12.3332 15.6667 10.6459 17.3334 8.56016 17.3334H4.43104C2.35376 17.3334 0.666504 15.6751 0.666504 13.6334V4.37508C0.666504 2.32508 2.36224 0.666748 4.43952 0.666748H8.57712C10.6459 0.666748 12.3332 2.32508 12.3332 4.36675V8.35841H7.24596ZM15.3584 6.11692L17.7917 8.54192C17.9167 8.66692 17.9834 8.82525 17.9834 9.00025C17.9834 9.16692 17.9167 9.33359 17.7917 9.45025L15.3584 11.8753C15.2334 12.0003 15.0667 12.0669 14.9084 12.0669C14.7417 12.0669 14.575 12.0003 14.45 11.8753C14.2 11.6253 14.2 11.2169 14.45 10.9669L15.7834 9.64192H12.3334V8.35859H15.7834L14.45 7.03359C14.2 6.78359 14.2 6.37525 14.45 6.12525C14.7 5.86692 15.1084 5.86692 15.3584 6.11692Z" fill="#030229" />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </ul>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SaSidebar