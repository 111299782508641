import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { baseurl } from '../../config/Base_url';

const PieChart = () => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedToken = localStorage.getItem('authToken');
                const userDetail = JSON.parse(localStorage.getItem('user'));
                const response = await fetch(`${baseurl}/api/user/total-num-collection/report/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                const chartData = [
                    ['Type', 'Value'],
                    ['Profit', data.profit],
                    ['Current', data.totalInNumberDifference],
                    ['Previous', data.totalOutNumberDifference]
                ];
                setChartData(chartData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const options = {
        pieHole: 0.4,
        is3D: false,
        legend: {
            position: 'none',
        },
        pieSliceBorderColor: 'transparent',
        colors: ['#5B93FF', '#FFD66B', '#FF8F6B'] // Set specific colors for slices
    };

    const chartStyle = {
        '#reactgooglegraph-1 svg': {
            width: '200px',
            height: '300px',
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <Chart
                chartType="PieChart"
                width='100%'
                height='305px'
                data={chartData}
                options={options}
                chartStyle={chartStyle}
            />
            <div style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'inline-block', marginRight: '10px', backgroundColor: '#5B93FF', width: '15px', height: '15px', borderRadius: '4px', verticalAlign: 'middle' }}></div>
                <span style={{ verticalAlign: 'middle' }}>Profit</span>
                <div style={{ display: 'inline-block', marginLeft: '20px', marginRight: '10px', backgroundColor: '#FFD66B', width: '15px', height: '15px', borderRadius: '4px', verticalAlign: 'middle' }}></div>
                <span style={{ verticalAlign: 'middle' }}>Current</span>
                <div style={{ display: 'inline-block', marginLeft: '20px', marginRight: '10px', backgroundColor: '#FF8F6B', width: '15px', height: '15px', borderRadius: '4px', verticalAlign: 'middle' }}></div>
                <span style={{ verticalAlign: 'middle' }}>Previous</span>
            </div>
        </div>
    );
};

export default PieChart;
