import React, { useEffect, useState } from 'react';
import styles from './recentcollectionlist.module.css';
import axios from 'axios';
import { baseurl } from '../../config/Base_url';
import Loading from '../loading/Loading';
import { Spinner } from 'react-bootstrap';


const RecentCollectionList = () => {

    const [loading, setLoading] = useState()
    const [recentCollec, setRecentCollec] = useState()
    const storedToken = localStorage.getItem('authToken');
    const userDetail = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
            let response;
            setLoading(true)
            response = await axios.get(`${baseurl}/api/user/recent-collection/report/${userDetail._id}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            setLoading(false)
            setRecentCollec(response.data.data);

        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className={`${styles.collectionlist}`}>
            {loading && (
                <div>
                    <div className={styles.loadingContainer}>
                        <Spinner size='sm' animation="border" role="status" className={styles.loadingSpinner}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                </div>
            )}
            {!loading && (
                <div>
                    {recentCollec?.length === 0 ? (
                        <div className='text-center'>No data found</div>
                    ) : (
                        recentCollec?.map((item) => (
                            item.machines.map((machine) => (
                                <div className='row' key={machine._id}>
                                    <div className="col-2">
                                        <p>{machine.machineNumber}-{machine.serialNumber}</p>
                                    </div>
                                    <div className="col-2">
                                        <p>{item?.location?.locationname}</p>
                                    </div>
                                    <div className="col-2">
                                        <p>{item?.location?.numofmachines}</p>
                                    </div>
                                    <div className="col-2">
                                        <p>{machine.inNumbers.current - machine.inNumbers.previous}</p>
                                    </div>
                                    <div className="col-2">
                                        <p>{machine.outNumbers.current - machine.outNumbers.previous}</p>
                                    </div>
                                    <div className="col-2">
                                        <p>{machine.total}</p>
                                    </div>
                                </div>
                            ))
                        ))
                    )}
                </div>
            )
            }
        </div>
    )
}

export default RecentCollectionList
