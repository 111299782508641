import React from 'react'
import { Link } from 'react-router-dom'
import style from './saadminhead.module.css'

const SaAdminHead = () => {


    return (
        <>
            <div className={`px-5 ${style.adminhead}`}>
                <div className='row rounded-2 py-3'>
                    <div className='col-2'>
                        <div className="dropdown">
                            <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                Admin Name
                            </button>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                    Email Address
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                    Mobile Number
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                    Address
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='col-2'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                    Creation Date
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button">
                                    Pay
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SaAdminHead
