import React from 'react';
import style from './recentcollection.module.css'
import { Link } from 'react-router-dom';
import RecentCollectionList from '../recentcollectionlist/RecentCollectionList';
import { GrFormEdit } from 'react-icons/gr';

const RecentCollection = () => {
    return (
        <div className={`${style.mainrecentcollection} row pe-3 h-100`}>
            <div className={`${style.backgroundColor} overflow-auto`}>
                <div className={`${style.divwidth}`}>
                    <div className={`d-flex justify-content-between align-items-center py-0`}>
                        <div>
                            <h5 className=''>Recent Collection</h5>
                        </div>
                    </div>
                    <div className={`row mb-2 text-start`}>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-2">
                                    <div className="dropdown">
                                        <button className={`btn btn-transparent px-0 ${style.dropdownfontsize}`}>
                                            Serial no
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="dropdown">
                                        <button className={`btn btn-transparent px-0 ${style.dropdownfontsize}`} type="button" >
                                            Location
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="dropdown">
                                        <button className={`btn btn-transparent px-0 ${style.dropdownfontsize}`} type="button" >
                                            Machine
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="dropdown">
                                        <button className={`btn btn-transparent px-0 ${style.dropdownfontsize}`} type="button" >
                                            Current
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="dropdown">
                                        <button className={`btn btn-transparent px-0 ${style.dropdownfontsize}`} type="button" >
                                            Previous
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="dropdown">
                                        <button className={`btn btn-transparent px-0 ${style.dropdownfontsize}`} type="button" >
                                            Total
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row py-3'>
                        <RecentCollectionList />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecentCollection
