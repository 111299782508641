import React from 'react'
import { Link } from 'react-router-dom'
import style from './collectionReportHead.module.css'
import RepairList from '../repairlist/RepairList'
import CollectionReportList from '../collectionReportList/CollectionReportList'

const CollectionReport = () => {
    return (
        <>
            <div className={`${style.divwidth}`}>
                <div className={`${style.collectionreporthead} px-5`}>
                    <div className='row rounded-2 py-3'>
                        <div className='col-2 p-0'>
                            <div className="dropdown">
                                <button className={`btn ps-0 btn-transparent ${style.dropbtn}`} type="button">
                                    Creation Date
                                </button>
                            </div>
                        </div>
                        <div className='col-2 p-0'>
                            <div className='d-flex align-items-center'>
                                <div className="dropdown">
                                    <button className={`btn ps-0 btn-transparent ${style.dropbtn}`} type="button">
                                        Employee Name
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-2 ps-0'>
                            <div className='d-flex align-items-center'>
                                <div className="dropdown">
                                    <button className={`btn ps-0 btn-transparent ${style.dropbtn}`} type="button">
                                        Location
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-1 p-0'>
                            <div className='d-flex align-items-center'>
                                <div className="dropdown">
                                    <button className={`btn ps-0 btn-transparent ${style.dropbtn}`} type="button">
                                        Machine
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-2 p-0'>
                            <div className='d-flex align-items-center'>
                                <div className="dropdown">
                                    <button className={`btn  ps-0 btn-transparent ${style.dropbtn}`} type="button">
                                        Begginning
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-1 p-0'>
                            <div className='d-flex align-items-center'>
                                <div className="dropdown">
                                    <button className={`btn ps-0 btn-transparent ${style.dropbtn}`} type="button">
                                        Closed
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-2 p-0'>
                            <div className='d-flex align-items-center'>
                                <div className="dropdown">
                                    <button className={`btn ps-0 btn-transparent ${style.dropbtn}`} type="button">
                                        Address
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${style.collectionreportilst}`}>
                    <CollectionReportList />
                </div>
            </div>
        </>
    )
}

export default CollectionReport