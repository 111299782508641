import React from 'react'
import { Link } from 'react-router-dom'
import style from './machinehead.module.css'

const MachineHead = () => {
    return (
        <>
            <div className={`${style.mainadmin}`}>
                <div className={`row px-5`}>
                    <div className={`col-2 ${style.machinepadding}`}>
                        <div className="dropdown">
                            <button className={`btn text-start btn-transparent ${style.dropbtn}`} type="button" >
                                Machine Serial Number
                            </button>
                        </div>
                    </div>
                    <div className={`col-2 ${style.employeepadding}`}>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn text-start btn-transparent ${style.dropbtn}`} type="button" >
                                    Employee
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`col-2  ${style.locationpadding}`}>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn text-start btn-transparent ${style.dropbtn}`} type="button" >
                                    Location
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`col-2 ${style.intialpadding}`}>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn text-start btn-transparent ${style.dropbtn}`} type="button" >
                                    Initial Number
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`col-2 ${style.currentpadding}`}>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn ps-3 text-start btn-transparent ${style.dropbtn}`} type="button" >
                                    Currunt Number
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`col-2 ${style.statuspadding}`}>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn text-start btn-transparent ${style.dropbtn}`} type="button" >
                                    Status
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MachineHead