import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import style from './saadminlist.module.css'
import { GrFormEdit } from "react-icons/gr";
import axios from 'axios';
import { baseurl } from '../../config/Base_url';
import success from '../../assests/Success.png';
import Loading from '../../components/loading/Loading';
import { MdBlockFlipped } from "react-icons/md";
import { AiFillLeftCircle, AiFillRightCircle, AiOutlineDollar } from "react-icons/ai";
import { Modal, Button, Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { IconContext } from 'react-icons';
import { RiDeleteBin6Line } from "react-icons/ri";


const SaAdminList = ({ searchTerm, users: propUsers }) => {

    const [users, setUsers] = useState([]);
    const navigate = useNavigate()
    const [showEditSuccessModal, setShowEditSuccessModal] = useState(null)
    const [showEditErrorModal, setShowEditErrorModal] = useState(null)

    const [showPaidCashSuccessModal, setShowPaidCashSuccessModal] = useState(null)
    const [showPaidCashErrorModal, setShowPaidCashErrorModal] = useState(null)
    const [paidErrorMsg, setPaidErrorMsg] = useState("")

    const [showBlockSuccessModal, setShowBlockSuccessModal] = useState(null)
    const [showBlockErrorModal, setShowBlockErrorModal] = useState(null)
    const [blockErrorMsg, setBlockErrorMsg] = useState("")

    const [showUnBlockSuccessModal, setShowUnBlockSuccessModal] = useState(null)
    const [showUnBlockErrorModal, setShowUnBlockErrorModal] = useState(null)
    const [unBlockErrorMsg, setUnBlockErrorMsg] = useState("")

    const [showDeleAdminSuccessModal, setShowDeleAdminSuccessModal] = useState(null)
    const [showDeleAdminErrorModal, setShowDeleAdminErrorModal] = useState(null)
    const [deleAdminErrorMsg, setDeleAdminErrorMsg] = useState("")

    const [page, setPage] = useState(0);
    const [filterData, setFilterData] = useState();
    const [n, setN] = useState(9);

    const perPageOptions = [
        { value: 9, label: '9' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
    ];

    const statusOptions = [
        { value: 'paid', label: 'Paid' },
        { value: 'unpaid', label: 'Unpaid' },
    ];

    useEffect(() => {
        setFilterData(
            users?.users?.filter((item, index) => {
                return (index >= page * n) & (index < (page + 1) * n);
            })
        );
    }, [page, n, users]);

    const handlePageChange = (selectedPage) => {
        setPage(selectedPage.selected);
    };

    const handlePerPageChange = (event) => {
        setN(Number(event.target.value));
    };

    useEffect(() => {
        fetchData();
    }, [searchTerm]);

    useEffect(() => {
        setUsers(propUsers);
    }, [propUsers]);

    const storedToken = localStorage.getItem('authToken');
    const fetchData = async () => {
        try {
            setLoading(true); // Set loading to true when the function starts

            const params = {
                page: page + 1, // Adjust page number to start from 1 instead of 0
                limit: n,
                search: searchTerm
            };

            let response;
            if (searchTerm) {
                response = await axios.get(`${baseurl}/api/user/alluser`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                    params: params
                });
            } else {
                response = await axios.get(`${baseurl}/api/user/alluser`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
            }
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                navigate('/login')
            }
        } finally {
            setLoading(false);
        }
    };

    const [loading, setLoading] = useState(false)
    const [employeeId, setEmplyoeeId] = useState("");
    const [showEditModal, setShowEditModal] = useState(false)
    const [selectedEditEmployee, setSelectedEditEmployee] = useState(null);


    useEffect(() => {
        fetchData();
    }, [searchTerm]);

    //form details
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [editErrorMsg, setEditErrorMsg] = useState("")

    const handleEditClick = (item) => {
        setSelectedEditEmployee(item);

        setForm({
            firstname: item.firstname,
            lastname: item.lastname,
            phone: item.phone,
            address: item.address,
            paymentStatus: item?.status,
            email: item?.email,
            password: "",
        });
        setShowEditModal(true);
    };

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });
        if (!!errors[field])
            setErrors({
                ...errors,
                [field]: null,
            });
    };

    const validateForm = () => {
        const { firstname, lastname, address, phone, paymentStatus, email, password } = form;
        const newErrors = {};

        if (!firstname || firstname === "") newErrors.firstname = "Please Enter your Firstname";
        if (!lastname || lastname === "") newErrors.lastname = "Please Enter your Lastname";
        if (!phone || phone === "") {
            newErrors.phone = "Please Enter your mobile number";
        } else {
            // Phone number validation with regex
            const phoneRegex = /^\d{10}$/; // Matches exactly 10 digits
            if (!phoneRegex.test(phone)) {
                newErrors.phone = "Please enter a valid 10-digit phone number";
            }
        }
        if (!email || email === "") {
            newErrors.email = "Please Enter your Email";
        } else {
            // Email validation with regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                newErrors.email = "Please enter a valid Email";
            }
        }
        if (!address || address === "") newErrors.address = "Please Enter your address";
        if (!password || password === "") newErrors.password = "Please Enter your Password";
        if (!paymentStatus || paymentStatus === "") newErrors.paymentStatus = "Please Select Payment Status"

        return newErrors;
    };

    const handleEditAdmin = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            try {
                const response = await axios.put(
                    `${baseurl}/api/user/edit-user/${selectedEditEmployee._id}`,
                    {
                        firstname: form.firstname,
                        lastname: form.lastname,
                        address: form.address,
                        phone: form.phone,
                        status: form.paymentStatus
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${storedToken}`,
                        },
                    }
                );

                if (response && response.data && response.data.success) {
                    setShowEditSuccessModal(true);
                    setShowEditErrorModal(false);
                    fetchData();
                }

            } catch (error) {
                console.error('Error creating admin:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    setEditErrorMsg(error.response.data.message);
                    setShowEditErrorModal(true);
                    setShowEditSuccessModal(false);
                } else {
                    setEditErrorMsg('An error occurred. Please try again.');
                    setShowEditErrorModal(true);
                    setShowEditSuccessModal(false);
                }
            }
        }
    };

    useEffect(() => {
        const formErrors = validateForm();
        const isAnyFieldEmpty = Object.keys(form).some((key) => !form[key]);
        const hasErrors = Object.keys(formErrors).some((key) => !!formErrors[key]);

        setIsFormInvalid(isAnyFieldEmpty || hasErrors);
    }, [form]);


    const handlePaidinCash = async () => {
        try {
            const response = await axios.put(
                `${baseurl}/api/user/editstatus/${employeeId}`,
                {
                    status: "paid"
                },
                {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                }
            );
            if (response && response.data && response.data.success) {
                setShowPaidCashSuccessModal(true);
                setShowPaidCashErrorModal(false);
                fetchData();
            }
        } catch (error) {
            console.error('Error creating admin:', error);
            if (error.response && error.response.data && error.response.data.message) {
                setPaidErrorMsg(error.response.data.message);
                setShowPaidCashErrorModal(true);
                setShowPaidCashSuccessModal(false);
            } else {
                setPaidErrorMsg('An error occurred. Please try again.');
                setShowPaidCashErrorModal(true);
                setShowPaidCashSuccessModal(false);
            }
        }
    }


    // Block and unblock employee
    const handleDisableUser = async () => {
        try {

            const response = await axios.put(
                `${baseurl}/api/user/unble-disable/${employeeId}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                }
            );

            if (response && response.data && response.data.success) {
                setShowBlockSuccessModal(true);
                setShowBlockErrorModal(false);
                fetchData();
            }
        } catch (error) {
            console.error('Error blocking admin:', error);
            if (error.response && error.response.data && error.response.data.message) {
                setBlockErrorMsg(error.response.data.message);
                setShowBlockErrorModal(true);
                setShowBlockSuccessModal(false);
            } else {
                setBlockErrorMsg('An error occurred. Please try again.');
                setShowBlockErrorModal(true);
                setShowBlockSuccessModal(false);
            }
        }
    };

    const handleEnableUser = async () => {
        try {
            const response = await axios.put(
                `${baseurl}/api/user/unble-disable/${employeeId}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                }
            );

            if (response && response.data && response.data.success) {
                setShowUnBlockSuccessModal(true);
                setShowUnBlockErrorModal(false);
                fetchData();
            }
        } catch (error) {
            console.error('Error unblocking admin:', error);
            if (error.response && error.response.data && error.response.data.message) {
                setUnBlockErrorMsg(error.response.data.message);
                setShowUnBlockErrorModal(true);
                setShowUnBlockSuccessModal(false);
            } else {
                setUnBlockErrorMsg('An error occurred. Please try again.');
                setShowUnBlockErrorModal(true);
                setShowUnBlockSuccessModal(false);
            }
        }
    };


    const handleDeleteAdmin = async () => {
        try {
            const response = await axios.delete(
                `${baseurl}/api/user/${employeeId}`,
                {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                }
            );

            if (response && response.data && response.data.success) {
                setShowDeleAdminSuccessModal(true);
                setShowDeleAdminErrorModal(false);
                fetchData();
            }
        } catch (error) {
            console.error('Error unblocking admin:', error);
            if (error.response && error.response.data && error.response.data.message) {
                setDeleAdminErrorMsg(error.response.data.message);
                setShowDeleAdminErrorModal(true);
                setShowDeleAdminSuccessModal(false);
            } else {
                setDeleAdminErrorMsg('An error occurred. Please try again.');
                setShowDeleAdminErrorModal(true);
                setShowDeleAdminSuccessModal(false);
            }
        }
    };

    
    return (
        <div className={`mt-3 ${style.mainlist}`}>
            {loading && <Loading />}
            {!loading && (
                <div>
                    {filterData?.length === 0 ? (
                        <div className='text-center'>No data found</div>
                    ) : (
                        filterData && filterData?.map((item) => (
                                <div key={item._id} className={`${style.rowfontsize} px-5 row d-flex align-items-center justify-content-center bg-white`} style={{ flexWrap: "wrap" }}>
                                    <div className="col-2">
                                        <div>
                                            <p className='fw-bold mb-0'>{item.firstname}</p>
                                        </div>
                                    </div>
                                    <div className="col-2" style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                                        <div>
                                            <p className='fw-bold mb-0' >{item.email}</p>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div>
                                            <p className='fw-bold mb-0'>{item.phone}</p>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div>
                                            <p className='fw-bold mb-0'>{item.address}</p>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div>
                                            <p className='fw-bold mb-0'>{new Date(item.createdAt).toLocaleDateString()}</p>
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex align-items-center justify-content-between">
                                        <div>
                                            {item.accountStatus === true ? (
                                                <p className={`${style.blocked} fw-bold mb-0`}>Disable</p>
                                            ) : (
                                                <p className={`${style.unblocked} fw-bold mb-0`}>Enable</p>
                                            )}
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>
                                                {/* update location */}
                                                <div className="btn-group dropstart">
                                                    <button type="button" className={`btn dropdown-toggle p-0 ${style.dropbtn}`} data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="4" viewBox="0 0 14 4" fill="none">
                                                            <path d="M13.4874 0.512567C12.804 -0.170848 11.696 -0.170848 11.0126 0.512567C10.3291 1.19598 10.3291 2.30402 11.0126 2.98744C11.696 3.67085 12.804 3.67085 13.4874 2.98744C14.1709 2.30405 14.1709 1.19601 13.4874 0.512567Z" fill="#B3B3BF" />
                                                            <path d="M8.23744 0.512567C7.55402 -0.170848 6.44598 -0.170848 5.76257 0.512567C5.07915 1.19598 5.07915 2.30402 5.76257 2.98744C6.44598 3.67085 7.55402 3.67085 8.23744 2.98744C8.92085 2.30405 8.92085 1.19601 8.23744 0.512567Z" fill="#B3B3BF" />
                                                            <path d="M2.98744 0.512567C2.30402 -0.170848 1.19598 -0.170848 0.512564 0.512567C-0.170852 1.19598 -0.170852 2.30402 0.512564 2.98744C1.19598 3.67085 2.30402 3.67085 2.98744 2.98744C3.67085 2.30405 3.67085 1.19601 2.98744 0.512567Z" fill="#B3B3BF" />
                                                        </svg>
                                                    </button>
                                                    <ul className={`dropdown-menu ${style.dropmenu}`}>
                                                        <li>
                                                            <button
                                                                data-bs-toggle="modal"
                                                                href="#editemployee"
                                                                type="submit"
                                                                className={`${style.editbtn} mb-1 w-100 text-start`}
                                                                onClick={() => {
                                                                    if (item) {
                                                                        handleEditClick(item);
                                                                    }
                                                                }}
                                                            >
                                                                <span className='me-2'><GrFormEdit /></span>Edit
                                                            </button>
                                                        </li>
                                                        <li >
                                                            <button
                                                                data-bs-toggle="modal"
                                                                href='#paidincash'
                                                                type="submit"
                                                                className={`${style.paidbtn} mb-1  w-100 text-start`}
                                                                onClick={() => {
                                                                    if (item) {
                                                                        setEmplyoeeId(item._id);
                                                                    }
                                                                }}
                                                            >
                                                                <span><AiOutlineDollar className='me-2' /></span>Paid in Cash
                                                            </button>
                                                        </li>
                                                        <li >
                                                            <button
                                                                data-bs-toggle="modal"
                                                                href='#deleteadmin'
                                                                type="submit"
                                                                className={`${style.blockbtn} mb-1  w-100 text-start`}
                                                                onClick={() => {
                                                                    if (item) {
                                                                        setEmplyoeeId(item._id);
                                                                    }
                                                                }}
                                                            >
                                                                <span><RiDeleteBin6Line className='me-2' /></span>Remove Admin
                                                            </button>
                                                        </li>
                                                        {
                                                            item.accountStatus === true ? (
                                                                // If the user is blocked, show the "Unblock" button
                                                                <li >
                                                                    <button
                                                                        data-bs-toggle="modal"
                                                                        href='#unblock'
                                                                        type="submit"
                                                                        className={`${style.unblockbtn} mb-1 w-100 text-start`}
                                                                        onClick={() => {
                                                                            if (item) {
                                                                                setEmplyoeeId(item._id);
                                                                                // Additional code, if needed
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span className='me-2'><MdBlockFlipped /></span>Enable
                                                                    </button>
                                                                </li>
                                                            ) : (
                                                                // If the user is not blocked, show the "Block" button
                                                                <li>
                                                                    <button
                                                                        data-bs-toggle="modal"
                                                                        href='#mymodal'
                                                                        type="submit"
                                                                        className={`${style.blockbtn} mb-1 w-100 text-start`}
                                                                        onClick={() => {
                                                                            if (item) {
                                                                                setEmplyoeeId(item._id);
                                                                                // Additional code, if needed
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span className='me-2'><MdBlockFlipped /></span>Disable
                                                                    </button>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="modal fade" id="editemployee" aria-hidden="true" aria-labelledby="editemployee" tabIndex="-1">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="editemployee">Edit Admin</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body" >
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <Form>
                                                                            <div className='row'>
                                                                                <div className="col-6">
                                                                                    <Form.Group controlId="firstname" className={`${style.formgroup} me-2`}>
                                                                                        <Form.Label className={`${style.label}`}>First Name<span className='text-danger'>*</span></Form.Label>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            placeholder='Enter firstName here'
                                                                                            className={`${style.inputfield}`}
                                                                                            value={form.firstname}
                                                                                            onChange={(e) => setField("firstname", e.target.value)}
                                                                                            isInvalid={!!errors.firstname}
                                                                                        />
                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            {errors.firstname && <div className="text-danger">{errors.firstname}</div>}
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <Form.Group controlId='lastname' className={`${style.formgroup} ms-2`} >
                                                                                        <Form.Label className={`${style.label}`}>Lastname<span className='text-danger'>*</span></Form.Label>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            placeholder='Enter lastname here'
                                                                                            className={`${style.inputfield}`}
                                                                                            value={form.lastname}
                                                                                            onChange={(e) => setField("lastname", e.target.value)}
                                                                                            isInvalid={!!errors.lastname}
                                                                                        />
                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            {errors.lastname && <div className="text-danger">{errors.lastname}</div>}
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                </div>
                                                                            </div>
                                                                            <Form.Group controlId='email' className={`${style.formgroup}`}>
                                                                                <Form.Label className={`${style.label}`}>Email Address<span className='text-danger'>*</span></Form.Label>
                                                                                <Form.Control
                                                                                    type="email"
                                                                                    placeholder='Enter email address here'
                                                                                    className={`${style.inputfield}`}
                                                                                    value={form.email}
                                                                                    onChange={(e) => setField("email", e.target.value)}
                                                                                    isInvalid={!!errors.email}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {errors.email && <div className="text-danger">{errors.email}</div>}
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <Form.Group controlId='phone' className={`${style.formgroup}`}>
                                                                                <Form.Label className={`${style.label}`}>Mobile Number<span className='text-danger'>*</span></Form.Label>
                                                                                <Form.Control
                                                                                    type="tel"
                                                                                    placeholder='Enter mobile number here'
                                                                                    className={`${style.inputfield} w-100`}
                                                                                    value={form.phone}
                                                                                    onChange={(e) => setField("phone", e.target.value)}
                                                                                    isInvalid={!!errors.phone}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                                                                </Form.Control.Feedback>

                                                                            </Form.Group>
                                                                            <Form.Group controlId='address' className={`${style.formgroup}`}>
                                                                                <Form.Label className={`${style.label}`}>Address<span className='text-danger '>*</span></Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder='Enter address here'
                                                                                    className={`${style.inputfield} mb-3 w-100`}
                                                                                    value={form.address}
                                                                                    onChange={(e) => setField("address", e.target.value)}
                                                                                    isInvalid={!!errors.address}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {errors.address && <div className="text-danger">{errors.address}</div>}
                                                                                </Form.Control.Feedback>

                                                                            </Form.Group>
                                                                            <Form.Group controlId='paymentStatus' className={`${style.formgroup}`}>
                                                                                <Form.Label className={`${style.label}`}>Paid in cash<span className='text-danger '>*</span></Form.Label>
                                                                                <Form.Select
                                                                                    className={`${style.selectbox} w-100`}
                                                                                    value={form?.paymentStatus} // Set the value directly from form state
                                                                                    onChange={(e) => setField('paymentStatus', e.target.value)} // Update form state on change
                                                                                    isInvalid={!!errors.paymentStatus}
                                                                                >
                                                                                    <option value="">Select payment status</option>
                                                                                    {statusOptions.map((option) => (
                                                                                        <option key={option.value} value={option.value}>
                                                                                            {option.label}
                                                                                        </option>
                                                                                    ))}
                                                                                </Form.Select>
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {errors.paymentStatus && <div className="text-danger">{errors.paymentStatus}</div>}
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <Form.Group controlId='password' >
                                                                                <Form.Label className={`${style.label}`}>Password<span className='text-danger'>*</span></Form.Label>
                                                                                <Form.Control
                                                                                    type="password"
                                                                                    placeholder='Enter password here'
                                                                                    className={`${style.inputfield}`}
                                                                                    value={form.password}
                                                                                    onChange={(e) => setField("password", e.target.value)}
                                                                                    isInvalid={!!errors.password}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {errors.password && <div className="text-danger">{errors.password}</div>}
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                        </Form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className={`${style.closebtn}`}
                                                                    data-bs-dismiss="modal"
                                                                >
                                                                    Close</button>
                                                                <Button
                                                                    type="submit"
                                                                    className={`${style.createbtn} `}
                                                                    data-bs-dismiss={isFormInvalid ? "" : "modal"}
                                                                    onClick={(e) => {
                                                                        handleEditAdmin(e);
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Modal
                                                    show={showEditSuccessModal}
                                                    onHide={() => setShowEditSuccessModal(false)}
                                                    centered
                                                    backdropClassName="custom-backdrop"
                                                >
                                                    <Modal.Header closeButton>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="text-center">
                                                            <img src={success} alt="success" />
                                                            <p className="fw-bold">Admin is Edited Successfully!</p>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="primary" className={style.closebtn} onClick={() => setShowEditSuccessModal(false)}>
                                                            Close
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>

                                                <Modal
                                                    show={showEditErrorModal}
                                                    onHide={() => setShowEditErrorModal(false)}
                                                    centered
                                                    backdropClassName="custom-backdrop"
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Error</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>{editErrorMsg}</Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="primary" className={style.closebtn} onClick={() => setShowEditErrorModal(false)}>
                                                            Close
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                            {/* // paid in cash modal */}
                                            <div className="modal fade" id="paidincash" aria-hidden="true" aria-labelledby="paidincash" tabIndex="-1">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="paidincash">Paid in Cash Admin</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body" >
                                                            Are you sure do you want to Paid in Cash this admin?
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className={`${style.closebtn}`} data-bs-dismiss="modal">Close</button>
                                                            <button type="button"
                                                                className={`${style.createbtn} `}
                                                                data-bs-dismiss="modal"
                                                                onClick={handlePaidinCash}
                                                            >
                                                                Confirm</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* pain in cash modal */}

                                            <Modal
                                                show={showPaidCashSuccessModal}
                                                onHide={() => setShowPaidCashSuccessModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="text-center">
                                                        <img src={success} alt="success" />
                                                        <p className="fw-bold">Admin Paid Successfully!</p>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowPaidCashSuccessModal(false)}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>
                                            </Modal>

                                            <Modal
                                                show={showPaidCashErrorModal}
                                                onHide={() => setShowPaidCashErrorModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Error</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>{paidErrorMsg}</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowPaidCashErrorModal(false)}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                            {/* // Delete admin modal */}
                                            <div className="modal fade" id="deleteadmin" aria-hidden="true" aria-labelledby="deleteadmin" tabIndex="-1">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="deleteadmin">Delete Admin</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body" >
                                                            Are you sure do you want to Delete this admin?
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className={`${style.closebtn}`} data-bs-dismiss="modal">Close</button>
                                                            <button type="button"
                                                                className={`${style.createbtn} `}
                                                                data-bs-dismiss="modal"
                                                                onClick={handleDeleteAdmin}
                                                            >
                                                                Confirm</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Delete Admin modal */}

                                            <Modal
                                                show={showDeleAdminSuccessModal}
                                                onHide={() => setShowDeleAdminSuccessModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="text-center">
                                                        <img src={success} alt="success" />
                                                        <p className="fw-bold">Admin Deleted Successfully!</p>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowDeleAdminSuccessModal(false)}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>
                                            </Modal>

                                            <Modal
                                                show={showDeleAdminErrorModal}
                                                onHide={() => setShowDeleAdminErrorModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                </Modal.Header>
                                                <Modal.Body>{deleAdminErrorMsg}</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowDeleAdminErrorModal(false)}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                            {/* // block modal */}
                                            <div className="modal fade" id="mymodal" aria-hidden="true" aria-labelledby="exampleModalToggleLabe2" tabIndex="-1">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="mymodal">Disable Admin</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body" >
                                                            Are you sure do you want to disable this admin?
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className={`${style.closebtn}`} data-bs-dismiss="modal">Close</button>
                                                            <button type="button"
                                                                className={`${style.createbtn} `}
                                                                data-bs-dismiss="modal"
                                                                onClick={handleDisableUser}
                                                            >
                                                                Disable</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal fade" id="unblock" aria-hidden="true" aria-labelledby="unblock" tabIndex="-1">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="unblock">Enable Admin</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body" >
                                                            Are you sure do you want to Enable this admin?
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className={`${style.closebtn}`} data-bs-dismiss="modal">Close</button>
                                                            <button type="button"
                                                                className={`${style.createbtn} `}
                                                                data-bs-dismiss="modal"
                                                                onClick={handleEnableUser}
                                                            >
                                                                Enable</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Modal
                                                show={showBlockSuccessModal}
                                                onHide={() => setShowBlockSuccessModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="text-center">
                                                        <img src={success} alt="success" />
                                                        <p className="fw-bold">Admin is Disable Successfully!</p>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowBlockSuccessModal(false)}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>
                                            </Modal>

                                            <Modal
                                                show={showBlockErrorModal}
                                                onHide={() => setShowBlockErrorModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Error</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>{blockErrorMsg}</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" onClick={() => setShowBlockErrorModal(false)}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                            <Modal
                                                show={showUnBlockSuccessModal}
                                                onHide={() => setShowUnBlockSuccessModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="text-center">
                                                        <img src={success} alt="success" />
                                                        <p className="fw-bold">Admin is Enable Successfully!</p>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowUnBlockSuccessModal(false)}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>
                                            </Modal>

                                            <Modal
                                                show={showUnBlockErrorModal}
                                                onHide={() => setShowUnBlockErrorModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Error</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>{unBlockErrorMsg}</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" onClick={() => setShowUnBlockErrorModal(false)}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                        </div>
                                    </div>
                                </div>
                        ))
                    )}
                </div>
            )

            }
            {
                users?.users?.length > 10 && (
                    <div className="d-flex justify-content-end">
                        <div className='d-flex align-items-center me-3'>
                            <div className='me-1'>
                                Rows Per Page:
                            </div>
                            <div>
                                <Form.Select size="sm" value={n} onChange={handlePerPageChange}>
                                    {perPageOptions.map((option, idx) => (
                                        <option key={idx} value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <ReactPaginate
                            containerClassName={"pagination align-items-center mb-0"}
                            activeClassName={`${style.pagination}`}
                            pageClassName={"page-item px-2"}
                            onPageChange={handlePageChange}
                            breakLabel={<span className="break-label">...</span>}
                            pageCount={Math.ceil(users?.users?.length / n)}
                            previousLabel={
                                <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                                    <AiFillLeftCircle />
                                </IconContext.Provider>
                            }
                            nextLabel={
                                <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                                    <AiFillRightCircle />
                                </IconContext.Provider>
                            }
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default SaAdminList
