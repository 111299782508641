import React, { useEffect, useState } from 'react'
import { GoPlus } from "react-icons/go";
import styles from './superadminemployee.module.css'
import success from '../../assests/Success.png'
import EmployeeHead from '../../components/employeehead/EmployeeHead';
import axios from 'axios';
import { baseurl } from '../../config/Base_url';
import EmployeeList from '../../components/employeelist/EmployeeList';
import { Modal, Button, Form } from 'react-bootstrap';


const SuperAdminEmployee = () => {

    const [searchTerm, setSearchTerm] = useState("");
    const [errorMsg, setErrorMsg] = useState("")
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [showSuccessModal, setShowSuccessModal] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(null);
    const storedToken = localStorage.getItem('authToken');
    const userDetail = JSON.parse(localStorage.getItem('user'))
    const [employeeList, setEmployeeList] = useState([]);

    useEffect(() => {
        fetchEmployeeData();
    }, [searchTerm]);


    const fetchEmployeeData = async () => {
        try {
            let response;
            if (searchTerm) {
                response = await axios.get(`${baseurl}/api/employee/allemployee`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                    params: { searchEmployee: searchTerm }
                });
            } else {
                response = await axios.get(`${baseurl}/api/employee/allemployee`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
            }
            setEmployeeList(response.data.employees);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };


    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    const validateForm = () => {
        const { firstname, lastname, email, password, address, phone } = form;
        const newErrors = {};

        if (!firstname || firstname === "") newErrors.firstname = "Please Enter your Firstname";
        if (!lastname || lastname === "") newErrors.lastname = "Please Enter your Lastname";
        if (!phone || phone === "") {
            newErrors.phone = "Please Enter your mobile number";
        } else {
            // Phone number validation with regex
            const phoneRegex = /^\d{10}$/; // Matches exactly 10 digits
            if (!phoneRegex.test(phone)) {
                newErrors.phone = "Please enter a valid 10-digit phone number";
            }
        }
        if (!email || email === "") {
            newErrors.email = "Please Enter your Email";
        } else {
            // Email validation with regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                newErrors.email = "Please enter a valid Email";
            }
        }
        if (!address || address === "") newErrors.address = "Please Enter your address";
        if (!password || password === "") newErrors.password = "Please Enter your Password";

        return newErrors;
    };

    const handleCreateEmployee = async (e) => {

        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            try {
                const response = await axios.post(`${baseurl}/api/employee/register/${userDetail._id}`, form, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });

                if (response && response.data && response.data.success) {
                    setShowSuccessModal(true);
                    setShowErrorModal(false);
                    fetchEmployeeData();
                    handleModalClose();
                }
                

            } catch (error) {
                console.error('Error creating employee:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                    setShowErrorModal(true);
                    setShowSuccessModal(false);
                } else {
                    setErrorMsg('An error occurred. Please try again.');
                    setShowErrorModal(true);
                    setShowSuccessModal(false);
                }
            }
        }


    };

    useEffect(() => {
        const formErrors = validateForm();
        const isAnyFieldEmpty = Object.keys(form).some((key) => !form[key]);
        const hasErrors = Object.keys(formErrors).some((key) => !!formErrors[key]);

        setIsFormInvalid(isAnyFieldEmpty || hasErrors);
    }, [form]);

    const handleModalClose = () => {
        setField('firstname', '');
        setField('lastname', '');
        setField('phone', '');
        setField('email', '');
        setField('address', '');
        setField('password', '');
        setErrors({});
        setIsFormInvalid(true);
        setSearchTerm('');

    };


    return (
        <>
            <div className={` px-3 py-2 rounded-3 ${styles.adminmain} h-100`}>
                <div className={`d-flex justify-content-between align-items-center  ${styles.employeehead}`}>
                    <h3 className='me-5 fw-bold'>Employee</h3>
                    <div className={`d-flex ${styles.searchdisplay}`}>
                        <div className={`d-flex ${styles.search}`}>
                            <input
                                type="search"
                                className={`form-control border-0 bg-white border-0 shadow-none ${styles.searchfield}`}
                                placeholder="Search"
                                aria-label="Recipient's username with two button addons"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />

                            <button className={`me-2 fs-5 ${styles.searchbtn}`} type="button"><span className='me-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <g opacity="0.6">
                                        <path d="M6.14286 11.2857C8.98318 11.2857 11.2857 8.98318 11.2857 6.14286C11.2857 3.30254 8.98318 1 6.14286 1C3.30254 1 1 3.30254 1 6.14286C1 8.98318 3.30254 11.2857 6.14286 11.2857Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M13 13L10.4286 10.4286" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                </svg>
                            </span></button>
                        </div>
                        <a className={`${styles.addbtn} d-flex align-items-center`} data-bs-toggle="modal" href='#exampleModalToggle' role="button"><GoPlus className='fs-4' />Add New Employee</a>
                    </div>
                </div>
                <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered >
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <img src={success} alt="success" />
                            <p className="fw-bold">You have successfully Created a New Employee!</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" className={styles.closebtn} onClick={() => setShowSuccessModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered >
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMsg}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" className={styles.closebtn} onClick={() => setShowErrorModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className={`${styles.employee} h-100`}>
                    <div className={`${styles.divWidth} h-100`}>
                        <EmployeeHead />
                        <div className={`${styles.employeelist}`}>
                            <EmployeeList emplyoee={employeeList} searchTerm={searchTerm} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalToggleLabel">Add New Employee</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" >
                            <div className='row'>
                                <div className='col-12'>
                                    <Form>
                                        <div className='row'>
                                            <div className="col-6 pe-2">
                                                <Form.Group controlId="firstname" className={`${styles.formgroup}`}>
                                                    <Form.Label className={`${styles.label}`} l>First Name<span className='text-danger'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter first name here"
                                                        value={form.firstname}
                                                        onChange={(e) => setField("firstname", e.target.value)}
                                                        isInvalid={!!errors.firstname}
                                                    ></Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.firstname && <div className="text-danger">{errors.firstname}</div>}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-6 ps-2">
                                                <Form.Group controlId="lastname" className={`${styles.formgroup}`}>
                                                    <Form.Label className={`${styles.label}`}>Last Name<span className='text-danger'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter last name here"
                                                        value={form.lastname}
                                                        onChange={(e) => setField("lastname", e.target.value)}
                                                        isInvalid={!!errors.lastname}
                                                    ></Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.lastname}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <Form.Group controlId="email" className={`${styles.formgroup}`}>
                                            <Form.Label className={`${styles.label}`}>Email Address<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email address here"
                                                value={form.email}
                                                onChange={(e) => setField("email", e.target.value)}
                                                isInvalid={!!errors.email}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="phone" className={`${styles.formgroup}`}>
                                            <Form.Label className={`${styles.label}`}>Mobile Number<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control
                                                type="tel"
                                                placeholder="Enter mobile number here"
                                                value={form.phone}
                                                onChange={(e) => setField("phone", e.target.value)}
                                                isInvalid={!!errors.phone}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.phone}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="address" className={`${styles.formgroup}`}>
                                            <Form.Label className={`${styles.label}`}>Address<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter address here"
                                                value={form.address}
                                                onChange={(e) => setField("address", e.target.value)}
                                                isInvalid={!!errors.address}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.address}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="password">
                                            <Form.Label className={`${styles.label}`}>Password<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Enter password here"
                                                value={form.password}
                                                onChange={(e) => setField("password", e.target.value)}
                                                isInvalid={!!errors.password}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className={`${styles.closebtn}`}
                                                data-bs-dismiss="modal"
                                                onClick={handleModalClose}
                                            >
                                                Close</button>
                                            <Button
                                                type="submit"
                                                data-bs-dismiss={isFormInvalid ? "" : "modal"}
                                                className={`${styles.createbtn}`}
                                                onClick={(e) => {
                                                    handleCreateEmployee(e);
                                                }}
                                            >
                                                Create
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuperAdminEmployee