import React, { useEffect, useState } from 'react';
import styles from './dashboarddata.module.css'
import axios from 'axios';
import { baseurl } from '../../config/Base_url';

const DashboardData = () => {


    useEffect(() => {
        fetchLocationData();
        fetchMachineData();
        fetchEmployeeData();
    }, []);

    const [locationList, setLocationList] = useState([]);

    const storedToken = localStorage.getItem('authToken');
    const userDetail = JSON.parse(localStorage.getItem('user'));

    const fetchLocationData = async () => {
        try {
            let response;
            response = await axios.get(`${baseurl}/api/location/${userDetail._id}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            setLocationList(response.data.locations);
        }
        catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    // total machine
    const [machineList, setMachineList] = useState([]);

    const fetchMachineData = async () => {
        try {
            let response;
            response = await axios.get(`${baseurl}/api/user/machines/${userDetail._id}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            setMachineList(response.data.locations);
        }
        catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    const totalMachineCount = machineList.reduce((acc, item) => {
        return acc + (item.machines ? item.machines.length : 0);
    }, 0);

    // total employee 
    const [employeeList, setEmployeeList] = useState([]);

    const fetchEmployeeData = async () => {
        try {
            let response;
            response = await axios.get(`${baseurl}/api/employee/${userDetail._id}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            setEmployeeList(response.data.employees);
        }
        catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };


    return (
        <div>
            <div className={`row mt-4 mb-4 gap-4 ${styles.dashboardmain}`}>
                <div className={`col-xl-3 col-md-6 col-12 d-flex justify-content-center ${styles.dashboardData}`}>
                    <div className={`d-flex ps-5 align-items-center gap-4 w-100 ${styles.backgroundColorWhite}`}>
                        <div className={`${styles.bglocation}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M15 6V21M15 6L21 3V18L15 21M15 6L9 3M15 21L9 18M9 18L3 21V6L9 3M9 18V3" stroke="#5B93FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div>
                            <h3>{locationList.length}</h3>
                            <p>Locations</p>
                        </div>
                    </div>
                </div>
                <div className={`col-xl-3 col-md-6 col-12 d-flex justify-content-center ${styles.dashboardData} `}>
                    <div className={`d-flex ps-5 align-items-center gap-4 w-100 ${styles.backgroundColorWhite}`}>
                        <div className={`${styles.bgmachine}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M16 12V15M16 12H17.8031C18.921 12 19.48 12 19.9074 11.7822C20.2837 11.5905 20.5905 11.2837 20.7822 10.9074C21 10.48 21 9.921 21 8.80309V8.19691C21 7.07899 21 6.5192 20.7822 6.0918C20.5905 5.71547 20.2837 5.40973 19.9074 5.21799C19.4796 5 18.9203 5 17.8002 5H14.2002C13.0801 5 12.5196 5 12.0918 5.21799C11.7155 5.40973 11.4097 5.71547 11.218 6.0918C11 6.51962 11 7.08009 11 8.2002V8.80019C11 9.9203 11 10.4796 11.218 10.9074C11.4097 11.2837 11.7155 11.5905 12.0918 11.7822C12.5192 12 13.079 12 14.1969 12H16ZM7 10L5 12L3 10M3 7L5 5L7 7M3 21.0002V19.2002C3 18.0801 3 17.5196 3.21799 17.0918C3.40973 16.7155 3.71547 16.4097 4.0918 16.218C4.51962 16 5.08009 16 6.2002 16H17.8002C18.9203 16 19.4796 16 19.9074 16.218C20.2837 16.4097 20.5905 16.7155 20.7822 17.0918C21 17.5192 21 18.079 21 19.1969V21.0002" stroke="#FFC327" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div>
                            <h3>{totalMachineCount}</h3>
                            <p>Machines</p>
                        </div>
                    </div>
                </div>
                <div className={`col-xl-3 col-md-6 col-12 d-flex justify-content-center ${styles.dashboardData} `}>
                    <div className={`d-flex ps-5 align-items-center gap-4 w-100 ${styles.backgroundColorWhite}`}>
                        <div className={`${styles.bgemployee}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M17 20C17 18.3431 14.7614 17 12 17C9.23858 17 7 18.3431 7 20M21 17.0004C21 15.7702 19.7659 14.7129 18 14.25M3 17.0004C3 15.7702 4.2341 14.7129 6 14.25M18 10.2361C18.6137 9.68679 19 8.8885 19 8C19 6.34315 17.6569 5 16 5C15.2316 5 14.5308 5.28885 14 5.76389M6 10.2361C5.38625 9.68679 5 8.8885 5 8C5 6.34315 6.34315 5 8 5C8.76835 5 9.46924 5.28885 10 5.76389M12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z" stroke="#5B93FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div>
                            <h3>{employeeList.length}</h3>
                            <p>Emplyoees</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardData
