import React, { useEffect, useState } from 'react'
import style from './sareportchart.module.css'
import { Line } from '@ant-design/charts';

const SaLineChart = () => {
    const [data, setData] = useState([]);


    const staticData = [
        { Date: 'Jan', scales: 100 },
        { Date: 'Fab', scales: 250 },
        { Date: 'Mar', scales: 410 },
        { Date: 'Apr', scales: 100 },
        { Date: 'May', scales: 340 },
        { Date: 'Jun', scales: 80 },
        { Date: 'July', scales: 250 },
        { Date: 'Aug', scales: 180 },
        { Date: 'Sep', scales: 420 },
        { Date: 'Oct', scales: 100 },
        { Date: 'Nuv', scales: 120 },
        { Date: 'Dec', scales: 400 },
        // Add more data points as needed
    ];
    const config = {
        data: staticData,
        padding: 'auto',
        xField: 'Date',
        yField: 'scales',
        xAxis: {
            // type: 'timeCat',
            tickCount: 4,
        },
        smooth: true,
        
    };

    return <Line {...config} />;
}

export default SaLineChart
