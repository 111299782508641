import { Outlet, Navigate } from 'react-router-dom';


export default function SuperAdminRoutes() {
    
    const user = JSON.parse(localStorage.getItem('user'));
    const isSuperAdmin = user && user.role === 'superadmin';

    return (
        <>
            {isSuperAdmin ? <Outlet /> : <Navigate to="/login" />}
        </>
    );
}