import React, { useEffect, useState } from 'react'
import style from './machinelist.module.css'
import { GrFormEdit } from "react-icons/gr";
import axios from 'axios';
import { baseurl } from '../../config/Base_url';
import { RxSwitch } from 'react-icons/rx';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoMdAddCircleOutline } from 'react-icons/io';
import success from '../../assests/Success.png'
import Loading from '../loading/Loading';
import { Modal, Button, Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { IconContext } from 'react-icons';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { Typeahead } from 'react-bootstrap-typeahead';


const MachineList = ({ searchTerm, machines: propMachines }) => {

    const [loading, setLoading] = useState(false)
    const [machines, setMachines] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [locMacId, setLocMacId] = useState({ machineId: "", locationId: "" })

    const [showStatusSuccessModal, setShowStatusSuccessModal] = useState(null)
    const [showStatusErrorModal, setShowStatusErrorModal] = useState(null)
    const [statusErrorMsg, setStatusErrorMsg] = useState("")

    const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(null)
    const [showDeleteErrorModal, setShowDeleteErrorModal] = useState(null)
    const [deleteErrorMsg, setDeleteErrorMsg] = useState("")

    const [showEditSuccessModal, setShowEditSuccessModal] = useState(null)
    const [showEditErrorModal, setShowEditErrorModal] = useState(null)
    const [editErrorMsg, setEditErrorMsg] = useState("")

    const statusOptions = [
        { value: 'Active', label: 'Active' },
        { value: 'In Service', label: 'In Service' },
    ];

    //pagination
    const [page, setPage] = useState(0);
    const [filterData, setFilterData] = useState();
    const [n, setN] = useState(9);

    const perPageOptions = [
        { value: 9, label: '9' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
    ];

    useEffect(() => {
        setFilterData(
            machines?.filter((item, index) => {
                return (index >= page * n) & (index < (page + 1) * n);
            })
        );
    }, [page, n, machines]);

    const handlePageChange = (selectedPage) => {
        setPage(selectedPage.selected);
    };

    const handlePerPageChange = (event) => {
        setN(Number(event.target.value));
    };

    useEffect(() => {
        setMachines(propMachines);
    }, [propMachines]);

    useEffect(() => {
        fetchData();
    }, []);

    const storedToken = localStorage.getItem('authToken');
    const userDetail = JSON.parse(localStorage.getItem('user'));

    const fetchData = async () => {
        try {
            let response;
            setLoading(true)
            if (searchTerm) {
                response = await axios.get(`${baseurl}/api/user/machines/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                    params: { searchQuery: searchTerm }
                });
                setLoading(false)
            } else {
                setLoading(true)
                response = await axios.get(`${baseurl}/api/user/machines/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
                setLoading(false)
            }
            setMachines(response.data.locations);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    const [searchLocation, setSearchLocation] = useState("")
    const [searchEmployee, setSearchEmployee] = useState("")
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [location, setLocation] = useState([]);
    const [employee, setEmployee] = useState([])

    const options = employee?.map((item) => ({
        value: item._id,
        label: item.firstname
    }))

    useEffect(() => {
        fetchLocationData();
        fetchEmployeeData();
    }, [searchLocation]);


    const fetchLocationData = async () => {
        try {
            let response;
            if (searchLocation) {
                response = await axios.get(`${baseurl}/api/location/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                    params: { searchLocation: searchLocation }
                });
            } else {
                response = await axios.get(`${baseurl}/api/location/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
            }
            setLocation(response.data.locations);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    const fetchEmployeeData = async () => {
        try {
            let response;
            if (searchEmployee) {
                response = await axios.get(`${baseurl}/api/employee/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                    params: { searchEmployee: searchEmployee }
                });
            } else {
                response = await axios.get(`${baseurl}/api/employee/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
            }
            setEmployee(response.data.employees);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    // edit machine
    const [selectedEditMachine, setSelectedEditMachine] = useState(null);
    const [selectedEditLocation, setSelectedEditLocation] = useState(null);

    const [errors, setErrors] = useState("")
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [form, setForm] = useState({});

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });
        if (!!errors[field])
            setErrors({
                ...errors,
                [field]: null,
            });
    };

    const handleEditClick = (item, machine) => {
        setSelectedEditMachine(machine);
        setSelectedEditLocation(item);

        const defaultEmployeeIds = machine?.employees?.map((employee) => ({
            value: employee._id,
            label: employee.firstname,
        }));
        console.log("Default Employee IDs:", defaultEmployeeIds);

        setForm((prevForm) => ({
            ...prevForm,
            machineNumber: machine.machineNumber,
            serialNumber: machine.serialNumber,
            initialnumber: machine.initialnumber,
            gameName: machine.gameName,
            employee: defaultEmployeeIds,
        }));

        setSelectedEmployees(defaultEmployeeIds);
        setShowModal(true);
    };

    const validateForm = () => {
        const { machineNumber, serialNumber, initialnumber, employee, gameName } = form;
        const newErrors = {};

        if (!machineNumber || machineNumber === "") newErrors.machineNumber = "Please Enter Machine number";
        if (!serialNumber || serialNumber === "") newErrors.serialNumber = "Please Enter Machine Serial number";
        if (!initialnumber || initialnumber === "") newErrors.initialnumber = "Please Enter Initial Number";
        if (!employee || employee?.length === 0) newErrors.employee = "Please Select at least one employee";
        if (!gameName || gameName?.length === 0) newErrors.gameName = "Please Enter game name";

        return newErrors;
    };

    const handleEditMachine = async (e) => {

        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            try {
                const response = await axios.put(
                    `${baseurl}/api/user/edit-machine/${userDetail._id}`,
                    {
                        ...form,
                        machineId: `${selectedEditMachine._id}`,
                        employeeIds: selectedEmployees.map(emp => emp.value)
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${storedToken}`,
                        },
                    }
                );
                if (response && response.data && response.data.success) {
                    setShowEditSuccessModal(true);
                    setShowEditErrorModal(false);
                    fetchData();
                }
            }

            catch (error) {
                console.error('Error adding machine:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    setEditErrorMsg(error.response.data.message);
                    setShowEditErrorModal(true);
                    setShowEditSuccessModal(false);
                } else {
                    setEditErrorMsg('An error occurred. Please try again.');
                    setShowEditErrorModal(true);
                    setShowEditSuccessModal(false);
                }
            }
        }

    }

    useEffect(() => {
        const formErrors = validateForm();
        const isAnyFieldEmpty = Object.keys(form).some((key) => !form[key]);
        const hasErrors = Object.keys(formErrors).some((key) => !!formErrors[key]);

        setIsFormInvalid(isAnyFieldEmpty || hasErrors);
    }, [form]);

    const handleEmployeeSelection = (selectedOptions) => {
        setSelectedEmployees(selectedOptions);
        setSearchEmployee(selectedOptions.map(option => option.value));
        setField("employee", selectedOptions); // Update the form state for employeeSelection
    };

    // status change 
    const [isStatusFormInvalid, setIsStatusFormInvalid] = useState()

    const [statusForm, setStatusForm] = useState({});

    const [statusErrors, setStatusErrors] = useState({});


    const handleEditStatusClick = (item, machine) => {
        setSelectedEditMachine(machine);
        setSelectedEditLocation(item);

        setStatusForm({
            activeMachineStatus: machine?.activeMachineStatus,
        });
    }

    const setStatusField = (statusField, value) => {
        setStatusForm({
            ...statusForm,
            [statusField]: value,
        });

        if (!!statusErrors[statusField]) {
            setStatusErrors({
                ...statusErrors,
                [statusField]: null,
            });
        }
    };

    const validateStatusForm = () => {
        const { activeMachineStatus } = statusForm;
        const newErrors = {};


        if (!activeMachineStatus || activeMachineStatus === "") newErrors.activeMachineStatus = "Please Select Status";

        return newErrors;
    };

    const handleStatusMachine = async (e) => {
        e.preventDefault();

        const formStatusErrors = validateStatusForm();
        if (Object.keys(formStatusErrors).length > 0) {
            setStatusErrors(formStatusErrors);
        } else {
            try {
                console.log("click");
                const storedToken = localStorage.getItem('authToken');
                if (!storedToken) {
                    console.error('Authentication token not found.');
                    return;
                }
                const response = await axios.put(
                    `${baseurl}/api/user/machine/${userDetail._id}/${selectedEditMachine._id}/acitvestatus`, {
                    activeMachineStatus: statusForm.activeMachineStatus
                },
                    {
                        headers: {
                            Authorization: `Bearer ${storedToken}`,
                        },
                    }
                );
                if (response && response.data && response.data.success) {
                    setShowStatusSuccessModal(true);
                    setShowStatusErrorModal(false);
                    fetchData();
                }

            } catch (error) {
                console.error('Error updating location Status:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    setStatusErrorMsg(error.response.data.message);
                    setShowStatusErrorModal(true);
                    setShowStatusSuccessModal(false);
                } else {
                    setStatusErrorMsg('An error occurred. Please try again.');
                    setShowStatusErrorModal(true);
                    setShowStatusSuccessModal(false);
                }
            }
        }
    }

    useEffect(() => {
        const formStatusErrors = validateStatusForm();
        const isAnyFieldEmpty = Object.keys(statusForm).some((key) => !statusForm[key]);
        const hasErrors = Object.keys(formStatusErrors).some((key) => !!formStatusErrors[key]);

        setIsStatusFormInvalid(isAnyFieldEmpty || hasErrors);
    }, [statusForm]);

    // delete Machine
    const handleDeleteMachine = async () => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (!storedToken) {
                console.error('Authentication token not found.');
                return;
            }
            const response = await axios.delete(
                `${baseurl}/api/user/delete-machine/${userDetail._id}/machines/${locMacId.locationId}/location/${locMacId.machineId}`,
                {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                }
            );
            if (response && response.data && response.data.success) {
                setShowDeleteSuccessModal(true);
                setShowDeleteErrorModal(false);
                fetchData();
            }
        } catch (error) {
            console.error('Error delteting machine :', error);
            if (error.response && error.response.data && error.response.data.message) {
                setDeleteErrorMsg(error.response.data.message);
                setShowDeleteErrorModal(true);
                setShowDeleteSuccessModal(false);
            } else {
                setDeleteErrorMsg('An error occurred. Please try again.');
                setShowDeleteErrorModal(true);
                setShowDeleteSuccessModal(false);
            }
        }
    }
    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <div>
                    {filterData && filterData.every(location => !location.machines || location?.machines?.length === 0) ? (
                        <div className='text-center'>No data found</div>
                    ) : (
                        filterData?.map((item) => (
                            <div key={item._id} className='row d-flex'>
                                {item?.machines?.map((machine) => (
                                    <div key={machine._id} className={`${style.machinemain} row d-flex align-items-center justify-content-center px-5 bg-white mb-2`}>
                                        <div className={`col-2 ${style.machinepadding}`}>
                                            <div>
                                                <p className='fw-bold mb-0'>{machine.machineNumber}-{machine.serialNumber}</p>
                                            </div>
                                        </div>
                                        <div className={`col-2 ${style.employeepadding}`}>
                                            <div>
                                                <p className='fw-bold mb-0 ms-1'>{machine?.employees?.length > 0 ?
                                                    machine.employees.map((emp) => `${emp.firstname} ${emp.lastname}`).join(', ') : 'No Employee'}</p>
                                            </div>
                                        </div>
                                        <div className={`col-2 ${style.locationpadding}`}>
                                            <div>
                                                <p className='fw-bold mb-0 ms-1'>{item.locationname}</p>
                                            </div>
                                        </div>
                                        <div className={`col-2 ${style.intialpadding}`}>
                                            <div>
                                                <p className='fw-bold mb-0 ms-2'>{machine.inNumbers.current + machine.inNumbers.previous}</p>
                                            </div>
                                        </div>
                                        <div className={`col-2 ${style.currentpadding}`}>
                                            <div>
                                                <p className='fw-bold mb-0 ms-2'>{machine.outNumbers.current + machine.outNumbers.previous}</p>
                                            </div>
                                        </div>
                                        <div className={`col-2 d-flex align-items-center justify-content-between ${style.statuspadding}`}>
                                            <div className=''>
                                                <button className={`${style.statusbtn} ${machine.activeMachineStatus === 'Active' ? style.activeStatus : style.inServiceStatus}`}>
                                                    {machine.activeMachineStatus}
                                                </button>
                                            </div>
                                            <div>
                                                {/* update location */}
                                                <div className="btn-group dropstart">
                                                    <button type="button" className={`btn btn-secondary p-0 dropdown-toggle ${style.dropbtn}`} data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="4" viewBox="0 0 14 4" fill="none">
                                                            <path d="M13.4874 0.512567C12.804 -0.170848 11.696 -0.170848 11.0126 0.512567C10.3291 1.19598 10.3291 2.30402 11.0126 2.98744C11.696 3.67085 12.804 3.67085 13.4874 2.98744C14.1709 2.30405 14.1709 1.19601 13.4874 0.512567Z" fill="#B3B3BF" />
                                                            <path d="M8.23744 0.512567C7.55402 -0.170848 6.44598 -0.170848 5.76257 0.512567C5.07915 1.19598 5.07915 2.30402 5.76257 2.98744C6.44598 3.67085 7.55402 3.67085 8.23744 2.98744C8.92085 2.30405 8.92085 1.19601 8.23744 0.512567Z" fill="#B3B3BF" />
                                                            <path d="M2.98744 0.512567C2.30402 -0.170848 1.19598 -0.170848 0.512564 0.512567C-0.170852 1.19598 -0.170852 2.30402 0.512564 2.98744C1.19598 3.67085 2.30402 3.67085 2.98744 2.98744C3.67085 2.30405 3.67085 1.19601 2.98744 0.512567Z" fill="#B3B3BF" />
                                                        </svg>
                                                    </button>
                                                    <ul className={`dropdown-menu ${style.dropmenu}`}>
                                                        <li>
                                                            <button
                                                                data-bs-toggle="modal"
                                                                href="#editlocation"
                                                                type="submit"
                                                                className={`${style.editBtn} m-1 ms-0`}
                                                                onClick={() => {
                                                                    if (item && machine) {
                                                                        handleEditClick(item, machine);
                                                                    }
                                                                }}
                                                            >
                                                                <span className='me-2'><GrFormEdit /></span>Edit
                                                            </button>
                                                        </li>

                                                        <li >
                                                            <button
                                                                data-bs-toggle="modal"
                                                                href='#mymodal'
                                                                type="submit"
                                                                className={`${style.deleteBtn} m-1 ms-0`}
                                                                onClick={() => setLocMacId({ machineId: machine._id, locationId: item._id })}
                                                            >
                                                                <span><RiDeleteBinLine className='me-2' /></span>Delete
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                data-bs-toggle="modal"
                                                                href='#statusmodal'
                                                                type="submit"
                                                                className={`${style.statusBtn} m-1 ms-0`}
                                                                onClick={() => {
                                                                    if (item && machine) {
                                                                        handleEditStatusClick(item, machine);
                                                                    }
                                                                }}
                                                            >
                                                                <span className='me-2'><RxSwitch /></span>Status
                                                            </button>
                                                        </li>
                                                        <li >
                                                            <button
                                                                data-bs-toggle="modal"
                                                                href="#infomodal"
                                                                type="submit"
                                                                className={`${style.infoBtn} m-1 ms-0`}
                                                                onClick={() => {
                                                                    if (item && machine) {
                                                                        handleEditClick(item, machine);
                                                                    }
                                                                }}
                                                            >
                                                                <span ><IoMdAddCircleOutline className='me-2' /></span>Info
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="modal fade" id="editlocation" aria-hidden="true" aria-labelledby="editlocation" tabIndex="-1">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="editlocation">Edit Machine</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className={`${style.mainmodal} row`}>
                                                                    <div className='col-12'>
                                                                        <Form>
                                                                            <div className='row'>
                                                                                <div className="col-6 pe-2">
                                                                                    <Form.Group controlId="machineNumber" className={`${style.formgroup}`}>
                                                                                        <Form.Label className={`${style.label}`}>Machine Number<span className='text-danger'>*</span></Form.Label>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            placeholder='#12'
                                                                                            value={form.machineNumber}
                                                                                            onChange={(e) => setField("machineNumber", e.target.value)}
                                                                                            isInvalid={!!errors.machineNumber}
                                                                                        >
                                                                                        </Form.Control>
                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            {errors.machineNumber && <div className="text-danger">{errors.machineNumber}</div>}
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                </div>
                                                                                <div className="col-6 ps-2">
                                                                                    <Form.Group controlId="serialNumber" className={`${style.formgroup}`}>
                                                                                        <Form.Label className={`${style.label}`}>Serial Number<span className='text-danger'>*</span></Form.Label>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            placeholder='Enter Serial Number Here'
                                                                                            value={form.serialNumber}
                                                                                            onChange={(e) => setField("serialNumber", e.target.value)}
                                                                                            isInvalid={!!errors.serialNumber}
                                                                                        >
                                                                                        </Form.Control>
                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            {errors.serialNumber && <div className="text-danger">{errors.serialNumber}</div>}
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                </div>
                                                                            </div>
                                                                            <Form.Group controlId="gameName" className={`${style.formgroup}`}>
                                                                                <Form.Label className={`${style.label}`}>Game Name<span className='text-danger'>*</span></Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder='Enter game name here'
                                                                                    value={form.gameName}
                                                                                    onChange={(e) => setField("gameName", e.target.value)}
                                                                                    isInvalid={!!errors.gameName}
                                                                                >
                                                                                </Form.Control>
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {errors.gameName && <div className="text-danger">{errors.gameName}</div>}
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <Form.Group controlId="initialnumber" className={`${style.formgroup}`}>
                                                                                <Form.Label className={`${style.label}`}>Initial Number<span className='text-danger'>*</span></Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder='Enter machine initial number here'
                                                                                    value={form.initialnumber}
                                                                                    onChange={(e) => setField("initialnumber", e.target.value)}
                                                                                    isInvalid={!!errors.initialNumber}
                                                                                >
                                                                                </Form.Control>
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {errors.initialnumber && <div className="text-danger">{errors.initialnumber}</div>}
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <Form.Group controlId="employee">
                                                                                <Form.Label className={`${style.label}`}>Select Employee<span className='text-danger'>*</span></Form.Label>
                                                                                <Typeahead
                                                                                    id="basic-typeahead-multiple"
                                                                                    multiple
                                                                                    options={options}
                                                                                    placeholder="Select employees"
                                                                                    selected={selectedEmployees}
                                                                                    onChange={handleEmployeeSelection}
                                                                                    isInvalid={!!errors.employee}
                                                                                />

                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {errors.employee && <div className="text-danger">{errors.employee}</div>}
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <div className="modal-footer mt-3">
                                                                                <button type="button" className={`${style.closebtn}`} data-bs-dismiss="modal">Close</button>
                                                                                <button
                                                                                    type="button"
                                                                                    className={`${style.createbtn} `}
                                                                                    data-bs-dismiss={isFormInvalid ? "" : "modal"}
                                                                                    onClick={(e) => {
                                                                                        handleEditMachine(e);
                                                                                    }}
                                                                                >
                                                                                    Edit</button>
                                                                            </div>
                                                                        </Form>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <Modal show={showEditSuccessModal}
                                                    onHide={() => setShowEditSuccessModal(false)}
                                                    centered
                                                    backdropClassName="custom-backdrop"
                                                >
                                                    <Modal.Header closeButton>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="text-center">
                                                            <img src={success} alt="success" />
                                                            <p className="fw-bold">You have successfully Edited a Machine!</p>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="primary" className={style.closebtn} onClick={() => setShowEditSuccessModal(false)}>
                                                            Close
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>

                                                <Modal show={showEditErrorModal}
                                                    onHide={() => setShowEditErrorModal(false)}
                                                    centered
                                                    backdropClassName="custom-backdrop"
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Error</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>{editErrorMsg}</Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="primary" className={style.closebtn} onClick={() => setShowEditErrorModal(false)}>
                                                            Close
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                            {/* // delete modal */}
                                            <div className="modal fade" id="mymodal" aria-hidden="true" aria-labelledby="mymodal" tabIndex="-1">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="mymodal">Delete Machine</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body" >
                                                            Are you sure do you want to delete this Machine?
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className={`${style.closebtn}`} data-bs-dismiss="modal">Close</button>
                                                            <button type="button"
                                                                className={`${style.createbtn} `}
                                                                data-bs-dismiss="modal"
                                                                onClick={handleDeleteMachine}
                                                            >
                                                                Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Modal show={showDeleteSuccessModal}
                                                onHide={() => setShowDeleteSuccessModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="text-center">
                                                        <img src={success} alt="success" />
                                                        <p className="fw-bold">You have successfully Deleted a Location!</p>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowDeleteSuccessModal(false)}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                            <Modal show={showDeleteErrorModal}
                                                onHide={() => setShowDeleteErrorModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Error</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>{deleteErrorMsg}</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowDeleteErrorModal(false)}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                            {/* // status change modal */}
                                            <div className="modal fade" id="statusmodal" aria-hidden="true" aria-labelledby="statusmodal" tabIndex="-1">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="statusmodal">Change Status Machine</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body" >
                                                            <Form>
                                                                <Form.Group controlId='activeMachineStatus' className=''>
                                                                    <Form.Label className={`${style.label}`}>Select Status<span className='text-danger '>*</span></Form.Label>
                                                                    <Form.Select
                                                                        className={`${style.selectbox}`}
                                                                        value={statusForm?.activeMachineStatus} // Set the value directly from form state
                                                                        onChange={(e) => setStatusField('activeMachineStatus', e.target.value)} // Update form state on change
                                                                        isInvalid={!!statusErrors.activeMachineStatus}
                                                                    >
                                                                        <option value="">Select Status</option>
                                                                        {statusOptions.map((option) => (
                                                                            <option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {statusErrors.activeMachineStatus && <div className="text-danger">{statusErrors.activeMachineStatus}</div>}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>

                                                            </Form>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className={`${style.closebtn}`} data-bs-dismiss="modal">Close</button>
                                                            <Button
                                                                type="submit"
                                                                className={`${style.createbtn} `}
                                                                data-bs-dismiss={isStatusFormInvalid ? "" : "modal"}
                                                                onClick={(e) => {
                                                                    handleStatusMachine(e);
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Modal show={showStatusSuccessModal}
                                                onHide={() => setShowStatusSuccessModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="text-center">
                                                        <img src={success} alt="success" />
                                                        <p className="fw-bold">You have successfully Changed Status of a Machine!</p>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowStatusSuccessModal(false)}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                            <Modal show={showStatusErrorModal}
                                                onHide={() => setShowStatusErrorModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Error</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>{statusErrorMsg}</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowStatusErrorModal(false)}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                            {/* information modal */}
                                            <div className="modal fade" id="infomodal" aria-hidden="true" aria-labelledby="infomodal" tabIndex="-1">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body" >
                                                            <div className='text-center gap-2'>
                                                                <h4 className='fw-bold mb-3'> Information of Machine</h4>
                                                                <div className='border-bottom border-info border-3'></div>
                                                            </div>
                                                            <div className="mt-4 d-flex flex-column gap-3 text-center align-items-center justify-content-center">
                                                                <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                                    <h5>Machine Serial Number: </h5>
                                                                    <h5>{selectedEditMachine?.machineNumber}-{selectedEditMachine?.serialNumber}</h5>
                                                                </div>
                                                                <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                                    <h5>Employees: </h5>
                                                                    <h5>
                                                                        {selectedEditMachine?.employees.map((item) => `${item.firstname} ${item.lastname}`).join(', ')}
                                                                    </h5>
                                                                </div>
                                                                <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                                    <h5>Location Name:</h5>
                                                                    <h5 className='d-flex flex-wrap'>{selectedEditLocation?.locationname}</h5>
                                                                </div>
                                                                <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                                    <h5>Initial Number:</h5>
                                                                    <h5>$2000</h5>
                                                                </div>
                                                                <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                                    <h5>Current Number:</h5>
                                                                    <h5>$2000</h5>
                                                                </div>
                                                                <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                                    <h5>Status:</h5>
                                                                    <h5>{selectedEditMachine?.activeMachineStatus}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className={`${style.closebtn}`} data-bs-dismiss="modal">Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))
                    )}
                </div>
            )
            }
            {
                machines?.machines?.length > 7 && (
                    <div className="d-flex justify-content-end">
                        <div className='d-flex align-items-center me-3'>
                            <div className='me-1'>
                                Rows Per Page:
                            </div>
                            <div>
                                <Form.Select size="sm" value={n} onChange={handlePerPageChange}>
                                    {perPageOptions.map((option, idx) => (
                                        <option key={idx} value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <ReactPaginate
                            containerClassName={"pagination align-items-center mb-0"}
                            activeClassName={`${style.pagination}`}
                            pageClassName={"page-item px-2"}
                            onPageChange={handlePageChange}
                            breakLabel={<span className="break-label">...</span>}
                            pageCount={Math.ceil(machines?.length / n)}
                            previousLabel={
                                <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                                    <AiFillLeftCircle />
                                </IconContext.Provider>
                            }
                            nextLabel={
                                <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                                    <AiFillRightCircle />
                                </IconContext.Provider>
                            }
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                        />
                    </div>
                )}
        </>

    )
}

export default MachineList
