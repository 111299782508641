import React from 'react'
import { Link } from 'react-router-dom'
import style from './locationhead.module.css'

const LocationHead = () => {


    return (
        <>
            <div className={` ${style.adminhead} px-5`}>
                <div className='row rounded-2 pb-4'>
                    <div className='col-3'>
                        <div className="dropdown">
                            <button className={`btn btn-transparent ${style.dropbtn}`} type="button" >
                                Location
                            </button>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button" >
                                    Admin
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button" >
                                    Number Of Machines
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent ${style.dropbtn}`} type="button" >
                                    Creation Date
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn mb-0 btn-transparent ${style.dropbtn}`} type="button" >
                                    Status
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LocationHead
