import React, { useEffect, useState } from 'react'
import style from './pendingrepairs.module.css'
import { GrFormEdit } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { baseurl } from '../../config/Base_url'
import { Spinner } from 'react-bootstrap'

const PendingRepairs = () => {

    const [loading, setLoading] = useState()
    const [pendingRepairs, setPendingRepairs] = useState()
    const storedToken = localStorage.getItem('authToken');
    const userDetail = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
            let response;
            setLoading(true)
            response = await axios.get(`${baseurl}/api/user/pending-repairs/${userDetail._id}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            setLoading(false)
            setPendingRepairs(response.data.data);

        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className={`${style.mainrepairs} ps-3`}>
            <div className={`p-3 ${style.pendingrepair} `}>
                <div className={`${style.divwidth}`}>
                    <div className="d-flex justify-content-between align-items-center ">
                        <div>
                            <h5>Pending Repairs</h5>
                        </div>
                    </div>
                    {loading && (
                        <div>
                            <div className={style.loadingContainer}>
                                <Spinner size='sm' animation="border" role="status" className={style.loadingSpinner}>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        </div>
                    )}
                    {!loading && (
                        <div>
                            {pendingRepairs?.length === 0 ? (
                                <div className='text-center'>No data found</div>
                            ) : (
                                pendingRepairs?.map((report) => (
                                    report.machines.map((machine, index) => (
                                        <div key={index}>
                                            <div className={`${style.fontsize} d-flex align-items-center justify-content-between mb-4`}>
                                                <div>
                                                    <p>Serial No: {machine.machineNumber}-{machine.serialNumber}</p>
                                                    <p>Location: {report.location.locationname}</p>
                                                    <p>Issue: {machine.issue}</p>
                                                </div>
                                                <div className='me-5'>
                                                    <p>Reporter: {machine.reporterName}</p>
                                                    <p>Date: {new Date(machine.date).toLocaleDateString('en-US', {
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: 'numeric'
                                                    })}</p>
                                                    <p>Time: {machine.time}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ))
                            )}
                        </div>
                    )
                    }

                </div>
            </div>
        </div>
    )
}

export default PendingRepairs