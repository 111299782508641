import React from 'react'
import styles from './superadminhome.module.css'
import { Link } from 'react-router-dom'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import SaDashboardData from '../../superadmincomponents/sadashboarddata/SaDashboardData'
import SaLineChart from '../../superadmincomponents/sareportchart/SaLineChart'
import SaRecentCollection from '../../superadmincomponents/sarecentcollection/SaRecentCollection'
import SaPendingRepairs from '../../superadmincomponents/sapendingrepairs/SaPendingRepairs'

const SuperAdminHome = () => {
  return (
    <div className={`${styles.backgroundColore} px-3 d-flex flex-column`}>
      <div className={`d-flex align-items-center justify-content-between ${styles.dashboardhead}`}>
        <h3 className={`fw-bold `}>Dashboard</h3>
        <div className="dropdown">
          <button className={`${styles.dropbtn} ${styles.backgroundColorWhite} bg-white`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Select Location to check the report<span><MdOutlineKeyboardArrowDown /></span>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><Link className="dropdown-item" href="#">Action</Link></li>
            <li><Link className="dropdown-item" href="#">Another action</Link></li>
            <li><Link className="dropdown-item" href="#">Something else here</Link></li>
          </ul>
        </div>
      </div>
      <div className={`${styles.dashboarddata}`}>
        <SaDashboardData />
        <div className={` row`}>
          <div className={`col-xl-7 col-md-12 p-0`}>
            <div className={`${styles.linechart}`}>
              <h5 className='px-3 py-2'>Reports</h5>
              <div className={`${styles.chart}`}>
                <SaLineChart />
              </div>
            </div>
          </div>
        </div>
        <div className={`row mt-3`}>
          <div className='col-xl-7 p-0 col-md-12 col-sm-12'>
            <SaRecentCollection />
          </div>
          <div className='col-xl-5 p-0 col-md-12 col-sm-12'>
            <SaPendingRepairs />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuperAdminHome
