import React, { useState } from 'react'
import { IoSearch } from "react-icons/io5";
import styles from './serviceReport.module.css'
import ServiceReportHead from '../../components/servicereporthead/ServiceReportHead';
import ServiceReportList from '../../components/servicereportlist/ServiceReportList';

const ServiceReport = () => {

    return (
        <div className={`px-3 py-2 h-100 rounded-3 ${styles.adminmain}`}>
            <div className={`d-flex justify-content-between align-items-center ${styles.servicehead}`}>
                <h3 className='fw-bold'>Service Report</h3>
                <div className={`input-group  ${styles.searchinput}`}>
                    <input type="search" className={`form-control h-100 border-0 ${styles.searchfield}`} placeholder="Search" aria-label="Recipient's username with two button addons" />
                    <button className={`btn btn-outline-secondary me-2 border-0 fs-5 ${styles.searchbtn}`} type="button"><IoSearch className='fs-5' /></button>
                </div>
            </div>
            <div className={`${styles.servicereport} h-100`}>
                <div className={` ${styles.divwidth} h-100`}>
                    <ServiceReportHead />
                    <div className={`${styles.servicereportlist}`}>
                        <ServiceReportList />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceReport