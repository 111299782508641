import React from 'react';
import styles from './home.module.css';
import DashboardData from '../../components/dashboarddata/DashboardData';
import RecentCollection from '../../components/recentcollection/RecentCollection';
import LineChart from '../../components/reportchart/LineChart';
import PendingRepairs from '../../components/pendingrepairs/PendingRepairs';
import PieChart from '../../components/piechart/PieChart';

const Home = () => {
  return (
    <div className={`${styles.backgroundColore} h-100 px-3 d-flex flex-column`}>
      <div className={`d-flex align-items-center justify-content-between ${styles.dashboardhead}`}>
        <h3 className={`fw-bold `}>Dashboard</h3>
      </div>
     <div className={`${styles.dashboarddata}`}>
     <DashboardData />
      <div className={` row`}>
        <div className={`col-xl-8 col-md-12 col-sm-12`}>
          <div className={`${styles.linechart}`}>
            <h5 className='px-3 py-2'>Reports</h5>
            <div className={`${styles.chart}`}>
              <LineChart />
            </div>
          </div>
        </div>
        <div className={`col-xl-4 col-md-12 col-sm-12`}>
          <div className={`${styles.donutchart}`}>
            <h5 className={`px-3 ${styles.collection}`} >Collection</h5>
            <div className={`${styles.piechart}`}>
              <PieChart />
            </div>
          </div>
        </div>
      </div>
      <div className={`d-flex row justify-content-center mt-3 `}>
        <div className='col-xl-8 col-md-12 col-sm-12'>
          <RecentCollection />
        </div>
        <div className='col-xl-4 col-md-12 col-sm-12'>
          <PendingRepairs />
        </div>
      </div>
     </div>
    </div>
  )
}

export default Home
