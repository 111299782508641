import React from 'react'
import { Outlet } from 'react-router-dom'
import styles from './superadminlayout.module.css'
import SaSidebar from '../sasidebar/SaSidebar'

const SuperAdminLayout = () => {
    return (
        <div className='row h-100'>
            <div className={`col-xl-2 col-md-3 col-2`}>
                <SaSidebar />
            </div>
            <div className={`${styles.outletbg} col-xl-10 col-md-9 col-10 `}>
                <Outlet />
            </div>
        </div>
    )
}

export default SuperAdminLayout
