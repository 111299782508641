import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home/Home';
import Layout from './components/layout/Layout';
import Login from './pages/login/Login';
import Location from './pages/location/Location';
import Machine from './pages/machine/Machine';
import Employee from './pages/employee/Employee';
import Report from './pages/report/Report';
import Repair from './pages/repair/Repair';
import ServiceReport from './pages/servicereport/ServiceReport';
import CollectionReport from './pages/collectionReport/CollectionReport.jsx';
import PrivateRoutes from './privateroute/PrivateRoute.jsx';
import SuperAdminRoutes from './privateroute/SuperAdminRoutes.jsx';
import SuperAdminHome from './pages/superadminhome/SuperAdminHome.jsx';
import SuperAdminAdmin from './pages/superadminadmin/SuperAdminAdmin.jsx';
import SuperAdminEmployee from './pages/superadminemployee/SuperAdminEmployee.jsx';
import SuperAdminLayout from './superadmincomponents/superadminlayout/SuperAdminLayout.jsx';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exect element={<PrivateRoutes />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/location" element={<Location />} />
            <Route path="/machine" element={<Machine />} />
            <Route path="/employee" element={<Employee />} />
            <Route path="/report" element={<Report />} />
            <Route path="/repair" element={<Repair />} />
            <Route path="/service-report" element={<ServiceReport />} />
            <Route path="/collection-report" element={<CollectionReport />} />
          </Route>
        </Route>
        <Route exect element={<SuperAdminRoutes />}>
          <Route element={<SuperAdminLayout />}>
            <Route path='/superadmin/home' element={<SuperAdminHome />} />
            <Route path='/superadmin/admin' element={<SuperAdminAdmin />} />
            <Route path='/superadmin/employee' element={<SuperAdminEmployee />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App
