import React from 'react'
import style from './sapendingrepairs.module.css'
import { GrFormEdit } from 'react-icons/gr'
import { Link } from 'react-router-dom'

const SaPendingRepairs = () => {
    return (
        <div className={`p-3 px-4 ${style.mainrepair}`}>
            <div className="d-flex justify-content-between align-items-center ">
                <div>
                    <h5>Pending Repairs</h5>
                </div>
                <div>
                    <div className="btn-group dropstart">
                        <button type="button" className={`btn btn-secondary dropdown-toggle ${style.dropbtn}`} data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="4" viewBox="0 0 14 4" fill="none">
                                <path d="M13.4874 0.512567C12.804 -0.170848 11.696 -0.170848 11.0126 0.512567C10.3291 1.19598 10.3291 2.30402 11.0126 2.98744C11.696 3.67085 12.804 3.67085 13.4874 2.98744C14.1709 2.30405 14.1709 1.19601 13.4874 0.512567Z" fill="#B3B3BF" />
                                <path d="M8.23744 0.512567C7.55402 -0.170848 6.44598 -0.170848 5.76257 0.512567C5.07915 1.19598 5.07915 2.30402 5.76257 2.98744C6.44598 3.67085 7.55402 3.67085 8.23744 2.98744C8.92085 2.30405 8.92085 1.19601 8.23744 0.512567Z" fill="#B3B3BF" />
                                <path d="M2.98744 0.512567C2.30402 -0.170848 1.19598 -0.170848 0.512564 0.512567C-0.170852 1.19598 -0.170852 2.30402 0.512564 2.98744C1.19598 3.67085 2.30402 3.67085 2.98744 2.98744C3.67085 2.30405 3.67085 1.19601 2.98744 0.512567Z" fill="#B3B3BF" />
                            </svg>
                        </button>
                        <ul className={`dropdown-menu ${style.dropmenu}`}>
                            <li><Link className=''><GrFormEdit className='fs-5' />Edit Location</Link></li>
                            <li><Link>Delete location</Link></li>
                            <li><Link>Change Status</Link></li>
                            <li><Link>Add Machine to this Location</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <div className={`${style.fontsize} d-flex align-items-center justify-content-between mb-4`}>
                    <div>
                        <p>Serial No:#1-876364</p>
                        <p> Location:Moonlight Bar</p>
                        <p> Issue:Joy stick not working</p>
                    </div>
                    <div className='me-5'>
                        <p>Reporter:#1-876364</p>
                        <p>Date:15 DEc, 2023</p>
                        <p>Time:11:06 PM</p>
                    </div>
                </div>

                <div className={`${style.fontsize} d-flex align-items-center justify-content-between`}>
                    <div >
                        <p>Serial No:#1-876364</p>
                        <p> Location:Moonlight Bar</p>
                        <p> Issue:Joy stick not working</p>
                    </div>
                    <div className='me-5'>
                        <p>Reporter:#1-876364</p>
                        <p>Date:15 DEc, 2023</p>
                        <p >Time:11:06 PM</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SaPendingRepairs