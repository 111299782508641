import React from 'react'
import { Link } from 'react-router-dom'
import style from './repairhead.module.css'

const RepairHead = () => {


    return (
        <>
            <div className={`pb-4 px-5 ${style.repairhead}`}>
                <div className='row rounded-2'>
                    <div className='col-2'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent  ${style.dropbtn}`} type="button">
                                    Serial No
                                </button>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent  ${style.dropbtn}`} type="button">
                                    Location
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent  ${style.dropbtn}`} type="button">
                                    Reporter
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent  ${style.dropbtn}`} type="button">
                                    Issue
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-1'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent  ${style.dropbtn}`} type="button">
                                    Date
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-1'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent  ${style.dropbtn}`} type="button">
                                    Time
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-1'>
                        <div className='d-flex align-items-center'>
                            <div className="dropdown">
                                <button className={`btn btn-transparent  ${style.dropbtn}`} type="button">
                                    Status
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RepairHead
