import { Outlet, Navigate } from 'react-router-dom';

export default function PrivateRoutes() {
    const authToken = localStorage.getItem('authToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const isAdmin = user && user.role === 'admin';

    // Function to check if the token is expired
    const isTokenExpired = (token) => {
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode the token payload
            const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
            const currentTime = Date.now();
            return expirationTime < currentTime;
        }
        return true;
    };

    // Check if the token is expired or user is not an admin, then redirect to login
    if (!authToken || isTokenExpired(authToken) || !isAdmin) {
        return <Navigate to="/login" />;
    }

    return <Outlet />;
}
