import React, { useEffect, useState } from 'react';
import { Line } from '@ant-design/plots';
import { baseurl } from '../../config/Base_url';

const LineChart = () => {
    // State to store the data from the API
    const [chartData, setChartData] = useState([]);
    
    useEffect(() => {
        // Define your bearer token
        const storedToken = localStorage.getItem('authToken');
        const userDetail = JSON.parse(localStorage.getItem('user'));

        // Fetch data from API with bearer token in the header
        fetch(`${baseurl}/api/user/total-collection/report/${userDetail._id}`, {
            headers: {
                'Authorization': `Bearer ${storedToken}`
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            return response.json();
        })
        .then(data => {
            // Update chartData with API data
            if (data.monthlyTotals) {
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const newData = monthNames.map((monthName, index) => ({
                    Date: monthName,
                    profit: data.monthlyTotals[index] || 0 // Use API data or default to 0
                }));
                setChartData(newData);
            } else {
                // Handle error or empty response
                console.error('Error: No monthlyTotals data found in API response');
            }
        })
        .catch(error => {
            console.error('Error fetching data from API:', error);
        });
    }, []);

    const config = {
        data: chartData,
        padding: 'auto',
        xField: 'Date',
        yField: 'profit',
        xAxis: {
            tickCount: 12, // Show all months
        },
        smooth: true,
    };

    return <Line {...config} />;
};

export default LineChart;
