import React from 'react'
import { IoSearch } from "react-icons/io5";
import styles from './collectionreport.module.css'
import CollectionReportHead from '../../components/collectionReportHead/CollectionReportHead';

const CollectionReport = () => {
    return (
        <div className={`px-3 rounded-3 h-100`}>
            <div className={`d-flex justify-content-between align-items-center ${styles.collectionreporthead}`}>
                <h3 className={`${styles.maindeading} me-5 fw-bold`}>Collection Report</h3>
                <div className={`input-group  ${styles.searchinput}`}>
                    <input type="search" className={`form-control h-100 border-0 bg-white ${styles.searchfield}`} placeholder="Search" aria-label="Recipient's username with two button addons" />
                    <button className={`btn btn-outline-secondary me-2 border-0 fs-5 bg-white ${styles.searchbtn}`} type="button"><IoSearch className='fs-5' /></button>
                </div>
            </div>
            <div className={`${styles.collectionreport} h-100`}>
                <CollectionReportHead />
            </div>
        </div>
    )
}

export default CollectionReport