import React, { useEffect, useRef, useState } from 'react'
import { GoPlus } from "react-icons/go";
import styles from './machine.module.css'
import success from '../../assests/Success.png'
import MachineHead from '../../components/machinehead/MachineHead';
import MachineList from '../../components/machinelist/MachineList';
import axios from 'axios';
import { baseurl } from '../../config/Base_url';
import { Button, Form, Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';



const Machine = () => {


    const [searchTerm, setSearchTerm] = useState("")
    const [searchLocation, setSearchLocation] = useState("")
    const [searchEmployee, setSearchEmployee] = useState("")
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [location, setLocation] = useState([]);
    const [employee, setEmployee] = useState([]);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState("")

    const options = employee?.map((item) => ({
        value: item._id,
        label: item.firstname
    }))

    const statusOptions = location?.map((item) => ({
        value: item._id,
        label: item.locationname
    }))

    useEffect(() => {
        fetchData();
        fetchEmployeeData();
        fetchMachineData();
    }, [searchLocation, searchTerm]);

    const storedToken = localStorage.getItem('authToken');
    const userDetail = JSON.parse(localStorage.getItem('user'));

    const fetchData = async () => {
        try {
            let response;
            if (searchLocation) {
                response = await axios.get(`${baseurl}/api/location/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                    params: { searchLocation: searchLocation }
                });
            } else {
                response = await axios.get(`${baseurl}/api/location/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
            }
            setLocation(response.data.locations);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    const fetchEmployeeData = async () => {
        try {
            let response;
            if (searchEmployee) {
                response = await axios.get(`${baseurl}/api/employee/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                    params: { searchEmployee: searchEmployee }
                });
            } else {
                response = await axios.get(`${baseurl}/api/employee/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
            }
            setEmployee(response.data.employees);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    // fetch machine detail
    const [machineList, setMachineList] = useState([]);

    const fetchMachineData = async () => {
        try {
            let response;
            if (searchTerm) {
                response = await axios.get(`${baseurl}/api/user/machines/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                    params: { searchQuery: searchTerm }
                });
            } else {
                response = await axios.get(`${baseurl}/api/user/machines/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
            }
            setMachineList(response.data.locations);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    // add machine

    const [errors, setErrors] = useState("")
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [form, setForm] = useState({});

    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    const validateForm = () => {
        const { machineNumber, serialNumber, initialnumber, location, employee,gameName } = form;
        const newErrors = {};

        if (!machineNumber || machineNumber === "") newErrors.machineNumber = "Please Enter Machine number";
        if (!serialNumber || serialNumber === "") newErrors.serialNumber = "Please Enter Machine Serial number";
        if (!initialnumber || initialnumber === "") newErrors.initialnumber = "Please Enter Initial Number";
        if (!location || location?.length === 0) newErrors.location = "Please Select at least one Location";
        if (!employee || employee?.length === 0) newErrors.employee = "Please Select at least one employee";
        if (!gameName || gameName?.length === 0) newErrors.gameName = "Please Enter game name";


        return newErrors;
    };

    const handleCreateMachine = async (e) => {

        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            try {
                const selectedEmployeeIds = selectedEmployees.map(employee => employee.value);
                const response = await axios.post(`${baseurl}/api/user/addmachine/${userDetail._id}`, {
                    ...form,
                    locationId: form.location,
                    employeeIds: selectedEmployeeIds,
                }, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`, // Include the authorization token in headers
                    },
                });
                if (response && response.data && response.data.success) {
                    setShowSuccessModal(true);
                    setShowErrorModal(false);
                    fetchMachineData();
                    handleModalClose();
                }
            } catch (error) {
                console.error('Error adding machine:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                    setShowErrorModal(true);
                    setShowSuccessModal(false);
                } else {
                    setErrorMsg('An error occurred. Please try again.');
                    setShowErrorModal(true);
                    setShowSuccessModal(false);
                }
            }
        }
    };

    useEffect(() => {
        const formErrors = validateForm();
        const isAnyFieldEmpty = Object.keys(form).some((key) => !form[key]);
        const hasErrors = Object.keys(formErrors).some((key) => !!formErrors[key]);

        setIsFormInvalid(isAnyFieldEmpty || hasErrors);
    }, [form]);


    const handleEmployeeSelection = (selectedOptions) => {
        setSelectedEmployees(selectedOptions);
        setSearchEmployee(selectedOptions.map(option => option.value));
        setField("employee", selectedOptions); // Update the form state for employeeSelection
    };

    const typeaheadRef = useRef(null);


    const handleModalClose = () => {
        setField('serialNumber', '');
        setField('machineNumber', '');
        setField('initialNumber', '');
        setField('gameName', '');
        setField('location', '');
        setField('employee', []);
        setErrors({});
        setSelectedEmployees([]);
        setIsFormInvalid(true);
        setSearchTerm('');
        setSearchEmployee('');

        if (typeaheadRef.current) {
            typeaheadRef.current.clear();
        }
    };

    return (
        <>
            <div className={`px-3 rounded-3 ${styles.adminhead}`}>
                <div className={`d-flex justify-content-between align-items-center  ${styles.machinhead}`}>
                    <h3 className='me-5 fw-bold'>Machine</h3>
                    <div className={`d-flex ${styles.searchdisplay}`}>
                        <div className={`d-flex ${styles.search}`}>
                            <input
                                type="search"
                                className={`form-control border-0 bg-white border-0 shadow-none ${styles.searchfield}`}
                                placeholder="Search"
                                aria-label="Recipient's username with two button addons"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />

                            <button className={`me-2 fs-5 ${styles.searchbtn}`} type="button"><span className='me-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <g opacity="0.6">
                                        <path d="M6.14286 11.2857C8.98318 11.2857 11.2857 8.98318 11.2857 6.14286C11.2857 3.30254 8.98318 1 6.14286 1C3.30254 1 1 3.30254 1 6.14286C1 8.98318 3.30254 11.2857 6.14286 11.2857Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M13 13L10.4286 10.4286" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                </svg>
                            </span></button>
                        </div>
                        <button className={`${styles.addbtn}`} data-bs-toggle="modal" href='#exampleModalToggle' type="button"><GoPlus className='fs-4' />Add New Machine</button>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggle" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalToggle">Add New Machine</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" >
                            <div className='row'>
                                <div className='col-12'>
                                    <Form>
                                        <div className='row'>
                                            <div className="col-6 pe-2">
                                                <Form.Group controlId="machineNumber" className={`${styles.formgroup}`}>
                                                    <Form.Label className={`${styles.label}`}>Machine Number<span className='text-danger'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='#12'
                                                        value={form.machineNumber}
                                                        onChange={(e) => setField("machineNumber", e.target.value)}
                                                        isInvalid={!!errors.machineNumber}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.machineNumber && <div className="text-danger">{errors.machineNumber}</div>}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-6 ps-2">
                                                <Form.Group controlId="serialNumber" className={`${styles.formgroup}`}>
                                                    <Form.Label className={`${styles.label}`}>Serial Number<span className='text-danger'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Enter serial number here'
                                                        value={form.serialNumber}
                                                        onChange={(e) => setField("serialNumber", e.target.value)}
                                                        isInvalid={!!errors.serialNumber}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.serialNumber && <div className="text-danger">{errors.serialNumber}</div>}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <Form.Group controlId="gameName" className={`${styles.formgroup}`}>
                                            <Form.Label className={`${styles.label}`}>Game Name<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder='Enter game name here'
                                                value={form.gameName}
                                                onChange={(e) => setField("gameName", e.target.value)}
                                                isInvalid={!!errors.gameName}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.gameName && <div className="text-danger">{errors.gameName}</div>}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="initialnumber" className={`${styles.formgroup}`}>
                                            <Form.Label className={`${styles.label}`}>Initial Number<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder='Enter machine initial number here'
                                                value={form.initialnumber}
                                                onChange={(e) => setField("initialnumber", e.target.value)}
                                                isInvalid={!!errors.initialnumber}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.initialnumber && <div className="text-danger">{errors.initialnumber}</div>}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="location" className={`${styles.formgroup}`}>
                                            <Form.Label className={`${styles.label}`}>Location<span className='text-danger'>*</span></Form.Label>
                                            <Form.Select
                                                className={`${styles.selectbox}`}
                                                value={form?.location} // Set the value directly from form state
                                                onChange={(e) => setField('location', e.target.value)} // Update form state on change
                                                isInvalid={!!errors.location}
                                            >
                                                <option value="">Select location</option>
                                                {statusOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.location && <div className="text-danger">{errors.location}</div>}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="employee">
                                            <Form.Label className={`${styles.label}`}>Select Employee<span className='text-danger'>*</span></Form.Label>
                                            <Typeahead
                                                ref={typeaheadRef}
                                                id="basic-typeahead-multiple"
                                                multiple
                                                options={options}
                                                placeholder="Select employees"
                                                value={selectedEmployees}
                                                onChange={handleEmployeeSelection}
                                                isInvalid={!!errors.employee}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.employee && <div className="text-danger">{errors.employee}</div>}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className={`${styles.closebtn}`}
                                                data-bs-dismiss="modal"
                                                onClick={handleModalClose}
                                            >
                                                Close</button>
                                            <Button
                                                type="button"
                                                className={`${styles.createbtn} `}
                                                data-bs-dismiss={isFormInvalid ? "" : "modal"}
                                                onClick={(e) => {
                                                    handleCreateMachine(e);
                                                }}
                                            >
                                                Create</Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <img src={success} alt="success" />
                        <p className="fw-bold">You have successfully Created a New Machine!</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className={styles.closebtn} onClick={() => setShowSuccessModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className={styles.closebtn} onClick={() => setShowErrorModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className={`${styles.machinelistscroll} h-100 px-3`}>
                <div className={`${styles.divWidth} h-100`}>
                    <MachineHead />
                    <div className={`${styles.machinlist}`}>
                        <MachineList searchTerm={searchTerm} machines={machineList} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Machine