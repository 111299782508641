import React, { useEffect, useState } from 'react'
import style from './servicereportlist.module.css'
import { Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { IconContext } from 'react-icons';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';


const ServiceReportList = () => {

    //pagination
    const [page, setPage] = useState(0);
    const [filterData, setFilterData] = useState();
    const [n, setN] = useState(9);

    const perPageOptions = [
        { value: 9, label: '9' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
    ];

    //  useEffect(() => {
    //      setFilterData(
    //          locations?.filter((item, index) => {
    //              return (index >= page * n) & (index < (page + 1) * n);
    //          })
    //      );
    //  }, [page, n, locations]);

    const handlePageChange = (selectedPage) => {
        setPage(selectedPage.selected);
    };

    const handlePerPageChange = (event) => {
        setN(Number(event.target.value));
    };


    return (
        <>
            <div className='mt-3'>
                <div className={`${style.servicereportlist} row px-5 align-items-center rounded-3 bg-white`}>
                    <div className="col-3">
                        <div>
                            <p className='fw-bold mb-0'>#1-652236</p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div>
                            <p className='fw-bold mb-0'>Steven</p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div>
                            <p className='fw-bold mb-0'>Joy stick not working</p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div>
                            <p className='fw-bold mb-0'>15 Dec, 2023</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="d-flex justify-content-end">
                <div className='d-flex align-items-center me-3'>
                    <div className='me-1'>
                        Rows Per Page:
                    </div>
                    <div>
                        <Form.Select size="sm" value={n} onChange={handlePerPageChange}>
                            {perPageOptions.map((option, idx) => (
                                <option key={idx} value={option.value}>{option.label}</option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
                <ReactPaginate
                    containerClassName={"pagination align-items-center mb-0"}
                    activeClassName={`${style.pagination}`}
                    pageClassName={"page-item p-2 px-3"}
                    onPageChange={handlePageChange}
                    breakLabel={<span className="break-label">...</span>}
                    // pageCount={Math.ceil(locations?.length / n)}
                    previousLabel={
                        <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                            <AiFillLeftCircle />
                        </IconContext.Provider>
                    }
                    nextLabel={
                        <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                            <AiFillRightCircle />
                        </IconContext.Provider>
                    }
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                />
            </div>
        </>
    )
}

export default ServiceReportList
