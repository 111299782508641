import React, { useState } from 'react'
import styles from './sidebar.module.css';
import { IoIosMenu } from "react-icons/io";
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../assests/Union.png'
import profile from '../../assests/profile.png'
import { Modal } from 'react-bootstrap';

const Sidebar = () => {
    const navigate = useNavigate()
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const userDetail = JSON.parse(localStorage.getItem('user'))
    const [showModal, setShowModal] = useState(false);

    const handleLogout = () => {
        // Open the confirmation modal
        setShowModal(true);
    };

    const confirmLogout = () => {
        // Close the modal
        setShowModal(false);
    
        // Perform logout logic
        const rememberMe = localStorage.getItem('rememberMe');
        
        if (rememberMe === 'true') {
            const savedEmail = localStorage.getItem('savedEmail') || '';
            const savedPassword = localStorage.getItem('savedPassword') || '';
    
            // Save email and password back to local storage
            localStorage.setItem('savedEmail', savedEmail);
            localStorage.setItem('savedPassword', savedPassword);
        } else {
            // If "Remember Me" was not checked, clear the saved information
            localStorage.removeItem('savedEmail');
            localStorage.removeItem('savedPassword');
        }
    
        // Clear only authToken, user, and any other specific items
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
    
        navigate('/login');
    };

    const cancelLogout = () => {
        // Close the modal without logging out
        setShowModal(false);
    };

    return (
        <>
            <div className={`mt-4`}>
                {/* ... rest of your code */}
                <div className='d-flex flex-column ' >
                    <div className={`d-flex align-items-center justify-content-center gap-4 ${styles.marginlogo}`} onClick={toggleSidebar}>
                        <img src={logo} alt="" className='image-fluid' />

                        <h2 className={` ${styles.fontSizeRouter}`}>Route <br /> Runner</h2>
                    </div>
                    <div className='text-center'> <button
                        className={`btn mt-2 h1 mb-2 d-lg-none d-md-none ${styles.togglebtn}`}
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvas"
                        type="button"
                    >
                        <IoIosMenu />
                    </button></div>
                    <ul className={`list-unstyled mt-3 ${styles.sidebarmenu}`}>
                        <li>
                            <div className={`d-flex gap-3 align-items-center mb-2 `}>
                                <NavLink to={"/"} className={({ isActive }) =>
                                    isActive ? `text-decoration-none w-100 text-secondary ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                }>
                                    <div className={`d-flex ${styles.startpadding}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                            <path d="M20 17.0002V11.4522C20 10.9179 19.9995 10.6506 19.9346 10.4019C19.877 10.1816 19.7825 9.97307 19.6546 9.78464C19.5102 9.57201 19.3096 9.39569 18.9074 9.04383L14.1074 4.84383C13.3608 4.19054 12.9875 3.86406 12.5674 3.73982C12.1972 3.63035 11.8026 3.63035 11.4324 3.73982C11.0126 3.86397 10.6398 4.19014 9.89436 4.84244L5.09277 9.04383C4.69064 9.39569 4.49004 9.57201 4.3457 9.78464C4.21779 9.97307 4.12255 10.1816 4.06497 10.4019C4 10.6506 4 10.9179 4 11.4522V17.0002C4 17.932 4 18.3978 4.15224 18.7654C4.35523 19.2554 4.74432 19.6452 5.23438 19.8482C5.60192 20.0005 6.06786 20.0005 6.99974 20.0005C7.93163 20.0005 8.39808 20.0005 8.76562 19.8482C9.25568 19.6452 9.64467 19.2555 9.84766 18.7654C9.9999 18.3979 10 17.932 10 17.0001V16.0001C10 14.8955 10.8954 14.0001 12 14.0001C13.1046 14.0001 14 14.8955 14 16.0001V17.0001C14 17.932 14 18.3979 14.1522 18.7654C14.3552 19.2555 14.7443 19.6452 15.2344 19.8482C15.6019 20.0005 16.0679 20.0005 16.9997 20.0005C17.9316 20.0005 18.3981 20.0005 18.7656 19.8482C19.2557 19.6452 19.6447 19.2554 19.8477 18.7654C19.9999 18.3978 20 17.932 20 17.0002Z"
                                                stroke="#6c757d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className={`ms-3 my-2 ${styles.sidebarMenu}`}>Dashboard</p>
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex gap-3 align-items-center mb-2 '>
                                <NavLink to={"/location"} className={({ isActive }) =>
                                    isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                }>
                                    <div className={`d-flex  ${styles.startpadding}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                            <path d="M15 6V21M15 6L21 3V18L15 21M15 6L9 3M15 21L9 18M9 18L3 21V6L9 3M9 18V3" stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className={`ms-3 my-2 ${styles.sidebarMenu}`}>Location</p>
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex gap-3 align-items-center mb-2 '>
                                <NavLink to={"/machine"} className={({ isActive }) =>
                                    isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                }>
                                    <div className={`d-flex  ${styles.startpadding}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                            <path d="M16 12V15M16 12H17.8031C18.921 12 19.48 12 19.9074 11.7822C20.2837 11.5905 20.5905 11.2837 20.7822 10.9074C21 10.48 21 9.921 21 8.80309V8.19691C21 7.07899 21 6.5192 20.7822 6.0918C20.5905 5.71547 20.2837 5.40973 19.9074 5.21799C19.4796 5 18.9203 5 17.8002 5H14.2002C13.0801 5 12.5196 5 12.0918 5.21799C11.7155 5.40973 11.4097 5.71547 11.218 6.0918C11 6.51962 11 7.08009 11 8.2002V8.80019C11 9.9203 11 10.4796 11.218 10.9074C11.4097 11.2837 11.7155 11.5905 12.0918 11.7822C12.5192 12 13.079 12 14.1969 12H16ZM7 10L5 12L3 10M3 7L5 5L7 7M3 21.0002V19.2002C3 18.0801 3 17.5196 3.21799 17.0918C3.40973 16.7155 3.71547 16.4097 4.0918 16.218C4.51962 16 5.08009 16 6.2002 16H17.8002C18.9203 16 19.4796 16 19.9074 16.218C20.2837 16.4097 20.5905 16.7155 20.7822 17.0918C21 17.5192 21 18.079 21 19.1969V21.0002" stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className={`ms-3 my-2 ${styles.sidebarMenu}`}>Machine</p>
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex gap-3 align-items-center mb-2'>
                                <NavLink to={"/employee"} className={({ isActive }) =>
                                    isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                }>
                                    <div className={`d-flex  ${styles.startpadding}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                            <path d="M17 20C17 18.3431 14.7614 17 12 17C9.23858 17 7 18.3431 7 20M21 17.0004C21 15.7702 19.7659 14.7129 18 14.25M3 17.0004C3 15.7702 4.2341 14.7129 6 14.25M18 10.2361C18.6137 9.68679 19 8.8885 19 8C19 6.34315 17.6569 5 16 5C15.2316 5 14.5308 5.28885 14 5.76389M6 10.2361C5.38625 9.68679 5 8.8885 5 8C5 6.34315 6.34315 5 8 5C8.76835 5 9.46924 5.28885 10 5.76389M12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z"
                                                stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className={`ms-3 my-2 ${styles.sidebarMenu}`}>Employee</p>
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex gap-3 align-items-center mb-2'>
                                <NavLink to={"/report"} className={({ isActive }) =>
                                    isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                }>
                                    <div className={`d-flex  ${styles.startpadding}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                            <path d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12M12 3C16.9706 3 21 7.02944 21 12M12 3V12M21 12H12M18 18.5L12 12"
                                                stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className={`ms-3 my-2 ${styles.sidebarMenu}`}>Report</p>
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex gap-3 align-items-center mb-2'>
                                <NavLink to={"/collection-report"} className={({ isActive }) =>
                                    isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                }>
                                    <div className={`d-flex  ${styles.startpadding}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                            <path d="M14 6H6C5.06812 6 4.60192 6 4.23438 6.15224C3.74432 6.35523 3.35523 6.74481 3.15224 7.23486C3 7.60241 3 8.06835 3 9.00023C4.65685 9.00023 6 10.3429 6 11.9998C6 13.6566 4.65685 15 3 15C3 15.9319 3 16.3978 3.15224 16.7654C3.35523 17.2554 3.74432 17.6447 4.23438 17.8477C4.60192 17.9999 5.06812 18 6 18H14M14 6H18C18.9319 6 19.3978 6 19.7654 6.15224C20.2554 6.35523 20.6447 6.74481 20.8477 7.23486C20.9999 7.6024 20.9999 8.06835 20.9999 9.00023C19.343 9.00023 18 10.3431 18 12C18 13.6569 19.343 15 20.9999 15C20.9999 15.9319 20.9999 16.3978 20.8477 16.7654C20.6447 17.2554 20.2554 17.6447 19.7654 17.8477C19.3978 17.9999 18.9319 18 18 18H14M14 6V18"
                                                stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className={`ms-3 my-2 ${styles.sidebarMenu}`}>Collection Report</p>
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex gap-3 align-items-center mb-2'>
                                <NavLink to={"/repair"} className={({ isActive }) =>
                                    isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                }>
                                    <div className={`d-flex  ${styles.startpadding}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                            <path d="M13.6006 21.0761L19.0608 17.9236C19.6437 17.5871 19.9346 17.4188 20.1465 17.1834C20.3341 16.9751 20.4759 16.7297 20.5625 16.4632C20.6602 16.1626 20.6602 15.8267 20.6602 15.1568V8.84268C20.6602 8.17277 20.6602 7.83694 20.5625 7.53638C20.4759 7.26982 20.3341 7.02428 20.1465 6.816C19.9355 6.58161 19.6453 6.41405 19.0674 6.08043L13.5996 2.92359C13.0167 2.58706 12.7259 2.41913 12.416 2.35328C12.1419 2.295 11.8584 2.295 11.5843 2.35328C11.2744 2.41914 10.9826 2.58706 10.3997 2.92359L4.93843 6.07666C4.35623 6.41279 4.06535 6.58073 3.85352 6.816C3.66597 7.02428 3.52434 7.26982 3.43773 7.53638C3.33984 7.83765 3.33984 8.17436 3.33984 8.84742V15.1524C3.33984 15.8254 3.33984 16.1619 3.43773 16.4632C3.52434 16.7297 3.66597 16.9751 3.85352 17.1834C4.06548 17.4188 4.35657 17.5871 4.93945 17.9236L10.3997 21.0761C10.9826 21.4126 11.2744 21.5806 11.5843 21.6465C11.8584 21.7047 12.1419 21.7047 12.416 21.6465C12.7259 21.5806 13.0177 21.4126 13.6006 21.0761Z"
                                                stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9 11.9998C9 13.6566 10.3431 14.9998 12 14.9998C13.6569 14.9998 15 13.6566 15 11.9998C15 10.3429 13.6569 8.99976 12 8.99976C10.3431 8.99976 9 10.3429 9 11.9998Z"
                                                stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className={`ms-3 my-2 ${styles.sidebarMenu}`}>Repair</p>
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex gap-3 align-items-center mb-2'>
                                <NavLink to={"/service-report"} className={({ isActive }) =>
                                    isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                }>
                                    <div className={`d-flex  ${styles.startpadding}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                            <path d="M9 17H15M9 14H15M13.0004 3.00087C12.9048 3 12.7974 3 12.6747 3H8.2002C7.08009 3 6.51962 3 6.0918 3.21799C5.71547 3.40973 5.40973 3.71547 5.21799 4.0918C5 4.51962 5 5.08009 5 6.2002V17.8002C5 18.9203 5 19.4801 5.21799 19.9079C5.40973 20.2842 5.71547 20.5905 6.0918 20.7822C6.51921 21 7.079 21 8.19694 21L15.8031 21C16.921 21 17.48 21 17.9074 20.7822C18.2837 20.5905 18.5905 20.2842 18.7822 19.9079C19 19.4805 19 18.9215 19 17.8036V9.32568C19 9.20302 18.9999 9.09553 18.999 9M13.0004 3.00087C13.2858 3.00348 13.4657 3.01407 13.6382 3.05547C13.8423 3.10446 14.0379 3.18526 14.2168 3.29492C14.4186 3.41857 14.5918 3.59181 14.9375 3.9375L18.063 7.06298C18.4089 7.40889 18.5809 7.58136 18.7046 7.78319C18.8142 7.96214 18.8953 8.15726 18.9443 8.36133C18.9857 8.53379 18.9964 8.71454 18.999 9M13.0004 3.00087L13 5.80021C13 6.92031 13 7.48015 13.218 7.90797C13.4097 8.2843 13.7155 8.59048 14.0918 8.78223C14.5192 9 15.079 9 16.1969 9H18.999"
                                                stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className={`ms-3 my-2 ${styles.sidebarMenu}`}>Service Report</p>
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                        <div className={`d-flex gap-3 align-items-center  ${styles.userinfo}`}>
                            <img src={profile} alt="" style={{ width: "50px", height: "50px" }} className='rounded-3' />
                            <div>
                                <p className='mb-0'>{userDetail.firstname}</p>
                                <p className='mb-0 text-secondary'>Admin</p>
                            </div>
                            <button className='bg-transparent border-0' onClick={handleLogout}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <g opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.24596 8.35841C6.88138 8.35841 6.5931 8.64175 6.5931 9.00008C6.5931 9.35008 6.88138 9.64175 7.24596 9.64175H12.3332V13.6251C12.3332 15.6667 10.6459 17.3334 8.56016 17.3334H4.43104C2.35376 17.3334 0.666504 15.6751 0.666504 13.6334V4.37508C0.666504 2.32508 2.36224 0.666748 4.43952 0.666748H8.57712C10.6459 0.666748 12.3332 2.32508 12.3332 4.36675V8.35841H7.24596ZM15.3584 6.11692L17.7917 8.54192C17.9167 8.66692 17.9834 8.82525 17.9834 9.00025C17.9834 9.16692 17.9167 9.33359 17.7917 9.45025L15.3584 11.8753C15.2334 12.0003 15.0667 12.0669 14.9084 12.0669C14.7417 12.0669 14.575 12.0003 14.45 11.8753C14.2 11.6253 14.2 11.2169 14.45 10.9669L15.7834 9.64192H12.3334V8.35859H15.7834L14.45 7.03359C14.2 6.78359 14.2 6.37525 14.45 6.12525C14.7 5.86692 15.1084 5.86692 15.3584 6.11692Z" fill="#030229" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </ul >
                </div >
            </div >
            <div className={`offcanvas offcanvas-start  ${styles.sideBarDiv}`} tabIndex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="true">
                <div className={`${styles.toggleheader} offcanvas-header`}>
                    <div className='d-flex align-items-center gap-4 justify-content-center'>
                        <img src={logo} alt="" className='img-fluid' />
                        <h2 className={``}>Route <br /> Runner</h2>
                        <button type="button" className={`${styles.closebtn} btn-close text-reset`} data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                </div>
                <div>
                    <div className='d-flex flex-column'  >
                    <ul className={`list-unstyled mt-3 ${styles.togglesidebarmenu}`}>
                            <li>
                                <div className={`d-flex gap-3 align-items-center mb-2 `}>
                                    <NavLink to={"/"} className={({ isActive }) =>
                                        isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                    }>
                                        <div className={`${styles.togglemenu} d-flex  `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M20 17.0002V11.4522C20 10.9179 19.9995 10.6506 19.9346 10.4019C19.877 10.1816 19.7825 9.97307 19.6546 9.78464C19.5102 9.57201 19.3096 9.39569 18.9074 9.04383L14.1074 4.84383C13.3608 4.19054 12.9875 3.86406 12.5674 3.73982C12.1972 3.63035 11.8026 3.63035 11.4324 3.73982C11.0126 3.86397 10.6398 4.19014 9.89436 4.84244L5.09277 9.04383C4.69064 9.39569 4.49004 9.57201 4.3457 9.78464C4.21779 9.97307 4.12255 10.1816 4.06497 10.4019C4 10.6506 4 10.9179 4 11.4522V17.0002C4 17.932 4 18.3978 4.15224 18.7654C4.35523 19.2554 4.74432 19.6452 5.23438 19.8482C5.60192 20.0005 6.06786 20.0005 6.99974 20.0005C7.93163 20.0005 8.39808 20.0005 8.76562 19.8482C9.25568 19.6452 9.64467 19.2555 9.84766 18.7654C9.9999 18.3979 10 17.932 10 17.0001V16.0001C10 14.8955 10.8954 14.0001 12 14.0001C13.1046 14.0001 14 14.8955 14 16.0001V17.0001C14 17.932 14 18.3979 14.1522 18.7654C14.3552 19.2555 14.7443 19.6452 15.2344 19.8482C15.6019 20.0005 16.0679 20.0005 16.9997 20.0005C17.9316 20.0005 18.3981 20.0005 18.7656 19.8482C19.2557 19.6452 19.6447 19.2554 19.8477 18.7654C19.9999 18.3978 20 17.932 20 17.0002Z"
                                                    stroke="#6c757d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='ms-3'>Dashboard</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex gap-3 align-items-center mb-2 '>
                                    <NavLink to={"/location"} className={({ isActive }) =>
                                        isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                    }>
                                        <div className={`${styles.togglemenu} d-flex `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='my-2'>
                                                <path d="M15 6V21M15 6L21 3V18L15 21M15 6L9 3M15 21L9 18M9 18L3 21V6L9 3M9 18V3" stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='ms-3'>Location</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex gap-3 align-items-center mb-2'>
                                    <NavLink to={"/machine"} className={({ isActive }) =>
                                        isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                    }>
                                        <div className={`${styles.togglemenu} d-flex  `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M16 12V15M16 12H17.8031C18.921 12 19.48 12 19.9074 11.7822C20.2837 11.5905 20.5905 11.2837 20.7822 10.9074C21 10.48 21 9.921 21 8.80309V8.19691C21 7.07899 21 6.5192 20.7822 6.0918C20.5905 5.71547 20.2837 5.40973 19.9074 5.21799C19.4796 5 18.9203 5 17.8002 5H14.2002C13.0801 5 12.5196 5 12.0918 5.21799C11.7155 5.40973 11.4097 5.71547 11.218 6.0918C11 6.51962 11 7.08009 11 8.2002V8.80019C11 9.9203 11 10.4796 11.218 10.9074C11.4097 11.2837 11.7155 11.5905 12.0918 11.7822C12.5192 12 13.079 12 14.1969 12H16ZM7 10L5 12L3 10M3 7L5 5L7 7M3 21.0002V19.2002C3 18.0801 3 17.5196 3.21799 17.0918C3.40973 16.7155 3.71547 16.4097 4.0918 16.218C4.51962 16 5.08009 16 6.2002 16H17.8002C18.9203 16 19.4796 16 19.9074 16.218C20.2837 16.4097 20.5905 16.7155 20.7822 17.0918C21 17.5192 21 18.079 21 19.1969V21.0002" stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

                                            </svg>
                                            <p className='ms-3'>Machine</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex gap-3 align-items-center mb-2'>
                                    <NavLink to={"/employee"} className={({ isActive }) =>
                                        isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                    }>
                                        <div className={`${styles.togglemenu} d-flex  `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M17 20C17 18.3431 14.7614 17 12 17C9.23858 17 7 18.3431 7 20M21 17.0004C21 15.7702 19.7659 14.7129 18 14.25M3 17.0004C3 15.7702 4.2341 14.7129 6 14.25M18 10.2361C18.6137 9.68679 19 8.8885 19 8C19 6.34315 17.6569 5 16 5C15.2316 5 14.5308 5.28885 14 5.76389M6 10.2361C5.38625 9.68679 5 8.8885 5 8C5 6.34315 6.34315 5 8 5C8.76835 5 9.46924 5.28885 10 5.76389M12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z"
                                                    stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='ms-3'>Employee</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex gap-3 align-items-center mb-2'>
                                    <NavLink to={"/report"} className={({ isActive }) =>
                                        isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary `
                                    }>
                                        <div className={`${styles.togglemenu} d-flex  `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12M12 3C16.9706 3 21 7.02944 21 12M12 3V12M21 12H12M18 18.5L12 12"
                                                    stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='ms-3'>Report</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex gap-3 align-items-center mb-2'>
                                    <NavLink to={"/collection-report"} className={({ isActive }) =>
                                        isActive ? `text-decoration-none w-100 text-secondary  ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary p-2`
                                    }>
                                        <div className={`${styles.togglemenu} d-flex  `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M14 6H6C5.06812 6 4.60192 6 4.23438 6.15224C3.74432 6.35523 3.35523 6.74481 3.15224 7.23486C3 7.60241 3 8.06835 3 9.00023C4.65685 9.00023 6 10.3429 6 11.9998C6 13.6566 4.65685 15 3 15C3 15.9319 3 16.3978 3.15224 16.7654C3.35523 17.2554 3.74432 17.6447 4.23438 17.8477C4.60192 17.9999 5.06812 18 6 18H14M14 6H18C18.9319 6 19.3978 6 19.7654 6.15224C20.2554 6.35523 20.6447 6.74481 20.8477 7.23486C20.9999 7.6024 20.9999 8.06835 20.9999 9.00023C19.343 9.00023 18 10.3431 18 12C18 13.6569 19.343 15 20.9999 15C20.9999 15.9319 20.9999 16.3978 20.8477 16.7654C20.6447 17.2554 20.2554 17.6447 19.7654 17.8477C19.3978 17.9999 18.9319 18 18 18H14M14 6V18"
                                                    stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='ms-3'>Collection Report</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex gap-3 align-items-center mb-2'>
                                    <NavLink to={"/repair"} className={({ isActive }) =>
                                        isActive ? `text-decoration-none text-secondary w-100 p-2 ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary p-2`
                                    }>
                                        <div className={`${styles.togglemenu} d-flex  `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M13.6006 21.0761L19.0608 17.9236C19.6437 17.5871 19.9346 17.4188 20.1465 17.1834C20.3341 16.9751 20.4759 16.7297 20.5625 16.4632C20.6602 16.1626 20.6602 15.8267 20.6602 15.1568V8.84268C20.6602 8.17277 20.6602 7.83694 20.5625 7.53638C20.4759 7.26982 20.3341 7.02428 20.1465 6.816C19.9355 6.58161 19.6453 6.41405 19.0674 6.08043L13.5996 2.92359C13.0167 2.58706 12.7259 2.41913 12.416 2.35328C12.1419 2.295 11.8584 2.295 11.5843 2.35328C11.2744 2.41914 10.9826 2.58706 10.3997 2.92359L4.93843 6.07666C4.35623 6.41279 4.06535 6.58073 3.85352 6.816C3.66597 7.02428 3.52434 7.26982 3.43773 7.53638C3.33984 7.83765 3.33984 8.17436 3.33984 8.84742V15.1524C3.33984 15.8254 3.33984 16.1619 3.43773 16.4632C3.52434 16.7297 3.66597 16.9751 3.85352 17.1834C4.06548 17.4188 4.35657 17.5871 4.93945 17.9236L10.3997 21.0761C10.9826 21.4126 11.2744 21.5806 11.5843 21.6465C11.8584 21.7047 12.1419 21.7047 12.416 21.6465C12.7259 21.5806 13.0177 21.4126 13.6006 21.0761Z"
                                                    stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9 11.9998C9 13.6566 10.3431 14.9998 12 14.9998C13.6569 14.9998 15 13.6566 15 11.9998C15 10.3429 13.6569 8.99976 12 8.99976C10.3431 8.99976 9 10.3429 9 11.9998Z"
                                                    stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='ms-3'>Repair</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex gap-3 align-items-center mb-2'>
                                    <NavLink to={"/service-report"} className={({ isActive }) =>
                                        isActive ? `text-decoration-none text-secondary w-100 p-2 ${styles.bggradient}` : `${styles.navlinkpadding} text-decoration-none w-100 text-secondary p-2`
                                    }>
                                        <div className={`${styles.togglemenu} d-flex  `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M9 17H15M9 14H15M13.0004 3.00087C12.9048 3 12.7974 3 12.6747 3H8.2002C7.08009 3 6.51962 3 6.0918 3.21799C5.71547 3.40973 5.40973 3.71547 5.21799 4.0918C5 4.51962 5 5.08009 5 6.2002V17.8002C5 18.9203 5 19.4801 5.21799 19.9079C5.40973 20.2842 5.71547 20.5905 6.0918 20.7822C6.51921 21 7.079 21 8.19694 21L15.8031 21C16.921 21 17.48 21 17.9074 20.7822C18.2837 20.5905 18.5905 20.2842 18.7822 19.9079C19 19.4805 19 18.9215 19 17.8036V9.32568C19 9.20302 18.9999 9.09553 18.999 9M13.0004 3.00087C13.2858 3.00348 13.4657 3.01407 13.6382 3.05547C13.8423 3.10446 14.0379 3.18526 14.2168 3.29492C14.4186 3.41857 14.5918 3.59181 14.9375 3.9375L18.063 7.06298C18.4089 7.40889 18.5809 7.58136 18.7046 7.78319C18.8142 7.96214 18.8953 8.15726 18.9443 8.36133C18.9857 8.53379 18.9964 8.71454 18.999 9M13.0004 3.00087L13 5.80021C13 6.92031 13 7.48015 13.218 7.90797C13.4097 8.2843 13.7155 8.59048 14.0918 8.78223C14.5192 9 15.079 9 16.1969 9H18.999"
                                                    stroke="#9A9AA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='ms-3'>Service Report</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <div className={`d-flex gap-3 align-items-center ${styles.userinfo}`}>
                                <img src={profile} alt="" style={{ width: "50px", height: "50px" }} className='rounded-3' />
                                <div>
                                    <p className='mb-0'>{userDetail.firstname}</p>
                                    <p className='mb-0 text-secondary'>Admin</p>
                                </div>
                                <button className='bg-transparent border-0'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <g opacity="0.4">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.24596 8.35841C6.88138 8.35841 6.5931 8.64175 6.5931 9.00008C6.5931 9.35008 6.88138 9.64175 7.24596 9.64175H12.3332V13.6251C12.3332 15.6667 10.6459 17.3334 8.56016 17.3334H4.43104C2.35376 17.3334 0.666504 15.6751 0.666504 13.6334V4.37508C0.666504 2.32508 2.36224 0.666748 4.43952 0.666748H8.57712C10.6459 0.666748 12.3332 2.32508 12.3332 4.36675V8.35841H7.24596ZM15.3584 6.11692L17.7917 8.54192C17.9167 8.66692 17.9834 8.82525 17.9834 9.00025C17.9834 9.16692 17.9167 9.33359 17.7917 9.45025L15.3584 11.8753C15.2334 12.0003 15.0667 12.0669 14.9084 12.0669C14.7417 12.0669 14.575 12.0003 14.45 11.8753C14.2 11.6253 14.2 11.2169 14.45 10.9669L15.7834 9.64192H12.3334V8.35859H15.7834L14.45 7.03359C14.2 6.78359 14.2 6.37525 14.45 6.12525C14.7 5.86692 15.1084 5.86692 15.3584 6.11692Z" fill="#030229" />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </ul>

                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={cancelLogout}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to logout?</Modal.Body>
                <Modal.Footer>
                    <button
                        className={`${styles.closebtn}`}
                        onClick={cancelLogout}>
                        No
                    </button>
                    <button className={`${styles.createbtn}`} onClick={confirmLogout}>
                        Yes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Sidebar