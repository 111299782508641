import React, { useEffect, useState } from 'react';
import style from './employeelist.module.css';
import { GrFormEdit } from "react-icons/gr";
import { baseurl } from '../../config/Base_url';
import axios from 'axios';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { RxSwitch } from 'react-icons/rx';
import { RiDeleteBinLine } from 'react-icons/ri';
import success from '../../assests/Success.png'
import Loading from '../loading/Loading';
import { Modal, Button, Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { IconContext } from 'react-icons';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';

const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'In Active', label: 'In Active' },
];

const EmployeeList = ({ searchTerm, emplyoee: propEmployee }) => {

    const [loading, setLoading] = useState(false)
    const [emplyoee, setEmplyoee] = useState([]);
    const [employeeId, setEmplyoeeId] = useState("");
    const [showEditModal, setShowEditModal] = useState(false)
    const [selectedEditEmployee, setSelectedEditEmployee] = useState(null);

    const [showStatusSuccessModal, setShowStatusSuccessModal] = useState(null)
    const [showStatusErrorModal, setShowStatusErrorModal] = useState(null)
    const [statusErrorMsg, setStatusErrorMsg] = useState("")

    const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(null)
    const [showDeleteErrorModal, setShowDeleteErrorModal] = useState(null)
    const [deleteErrorMsg, setDeleteErrorMsg] = useState("")

    const [showInfoSuccessModal, setShowInfoSuccessModal] = useState(null)
    const [showInfoErrorModal, setShowInfoErrorModal] = useState(null)
    const [infoErrorMsg, setInfoErrorMsg] = useState("")

    //pagination
    const [page, setPage] = useState(0);
    const [filterData, setFilterData] = useState();
    const [n, setN] = useState(9);

    const perPageOptions = [
        { value: 9, label: '9' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
    ];

    useEffect(() => {
        setFilterData(
            emplyoee?.filter((item, index) => {
                return (index >= page * n) & (index < (page + 1) * n);
            })
        );
    }, [page, n, emplyoee]);

    const handlePageChange = (selectedPage) => {
        setPage(selectedPage.selected);
    };

    const handlePerPageChange = (event) => {
        setN(Number(event.target.value));
    };

    useEffect(() => {
        fetchData();
    }, [searchTerm]);

    useEffect(() => {
        setEmplyoee(propEmployee);
    }, [propEmployee]);

    const storedToken = localStorage.getItem('authToken');
    const userDetail = JSON.parse(localStorage.getItem('user'));

    const fetchData = async () => {
        try {
            let response;
            if (searchTerm) {
                setLoading(true)
                response = await axios.get(`${baseurl}/api/employee/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                    params: { searchEmployee: searchTerm }
                });
                setLoading(false)
            } else {
                setLoading(true)
                response = await axios.get(`${baseurl}/api/employee/${userDetail._id}`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
                setLoading(false)
            }
            setEmplyoee(response.data.employees);

        } catch (error) {
            console.error('Error fetching users:', error);
            if (error) {
                console.log(error);
            }
        }
    };

    //form details
    const [showEditSuccessModal, setShowEditSuccessModal] = useState(null)
    const [showEditErrorModal, setShowEditErrorModal] = useState(null)

    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [editErrorMsg, setEditErrorMsg] = useState("")

    const handleEditClick = (item) => {
        setSelectedEditEmployee(item);

        setForm({
            firstname: item.firstname,
            lastname: item.lastname,
            phone: item.phone,
            address: item.address,
            activeEmployeeStatus: item?.activeEmployeeStatus,
            email: item?.email,
            password: "",
        });
        setShowEditModal(true);
    };

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });
        if (!!errors[field])
            setErrors({
                ...errors,
                [field]: null,
            });
    };

    const validateForm = () => {
        const { firstname, lastname, address, phone, email, password, activeEmployeeStatus } = form;
        const newErrors = {};

        if (!firstname || firstname === "") newErrors.firstname = "Please Enter your Firstname";
        if (!lastname || lastname === "") newErrors.lastname = "Please Enter your Lastname";
        if (!phone || phone === "") {
            newErrors.phone = "Please Enter your mobile number";
        } else {
            // Phone number validation with regex
            const phoneRegex = /^\d{10}$/; // Matches exactly 10 digits
            if (!phoneRegex.test(phone)) {
                newErrors.phone = "Please enter a valid 10-digit phone number";
            }
        }
        if (!email || email === "") {
            newErrors.email = "Please Enter your Email";
        } else {
            // Email validation with regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                newErrors.email = "Please enter a valid Email";
            }
        }
        if (!address || address === "") newErrors.address = "Please Enter your address";
        if (!password || password === "") newErrors.password = "Please Enter your Password";
        if (!activeEmployeeStatus || activeEmployeeStatus === "") newErrors.activeEmployeeStatus = "Please Select at least one Status"


        return newErrors;
    };



    const handleEditEmployee = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            try {
                const response = await axios.put(
                    `${baseurl}/api/employee/edit-employee/${userDetail._id}/employee/${selectedEditEmployee._id}`,
                    {
                        firstname: form.firstname,
                        lastname: form.lastname,
                        email: form.email,
                        address: form.address,
                        phone: form.phone,
                        password: form.password
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${storedToken}`,
                        },
                    }
                );
                if (response && response.data && response.data.success) {
                    setShowEditSuccessModal(true);
                    setShowEditErrorModal(false);
                    fetchData();
                }
            } catch (error) {
                console.error('Error creating admin:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    setEditErrorMsg(error.response.data.message);
                    setShowEditErrorModal(true);
                    setShowEditSuccessModal(false);
                } else {
                    setEditErrorMsg('An error occurred. Please try again.');
                    setShowEditErrorModal(true);
                    setShowEditSuccessModal(false);
                }
            }
        }

    }

    useEffect(() => {
        const formErrors = validateForm();
        const isAnyFieldEmpty = Object.keys(form).some((key) => !form[key]);
        const hasErrors = Object.keys(formErrors).some((key) => !!formErrors[key]);

        setIsFormInvalid(isAnyFieldEmpty || hasErrors);
    }, [form]);


    // status change employee

    const [isStatusFormInvalid, setIsStatusFormInvalid] = useState()
    const [statusForm, setStatusForm] = useState({});
    const [statusErrors, setStatusErrors] = useState({});

    const validateStatusForm = () => {
        const { activeEmployeeStatus } = statusForm
        const newErrors = {};

        // Validate activeEmployeeStatus
        if (!activeEmployeeStatus || activeEmployeeStatus === "") newErrors.activeEmployeeStatus = "Please Select Status";


        return newErrors;
    };

    const handleEditStatusClick = (item) => {
        setSelectedEditEmployee(item);

        setStatusForm({
            activeEmployeeStatus: item?.activeEmployeeStatus,
        });
    }

    const setStatusField = (statusField, value) => {
        setStatusForm({
            ...statusForm,
            [statusField]: value,
        });

        if (!!statusErrors[statusField]) {
            setStatusErrors({
                ...statusErrors,
                [statusField]: null,
            });
        }
    };

    const handleStatusEmployee = async (e) => {

        e.preventDefault();

        const formStatusErrors = validateStatusForm();
        if (Object.keys(formStatusErrors).length > 0) {
            setStatusErrors(formStatusErrors);
        } else {
            try {
                const storedToken = localStorage.getItem('authToken');
                if (!storedToken) {
                    console.error('Authentication token not found.');
                    return;
                }
                const response = await axios.put(
                    `${baseurl}/api/employee/employeestatus/${userDetail._id}/${selectedEditEmployee._id}`, {
                    activeEmployeeStatus: statusForm.activeEmployeeStatus
                },
                    {
                        headers: {
                            Authorization: `Bearer ${storedToken}`,
                        },
                    }
                );
                if (response && response.data && response.data.success) {
                    setShowStatusSuccessModal(true);
                    setShowStatusErrorModal(false);
                    fetchData();
                }

            } catch (error) {
                console.error('Error updating location Status:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    setStatusErrorMsg(error.response.data.message);
                    setShowStatusErrorModal(true);
                    setShowStatusSuccessModal(false);
                } else {
                    setStatusErrorMsg('An error occurred. Please try again.');
                    setShowStatusErrorModal(true);
                    setShowStatusSuccessModal(false);
                }
            }
        }


    }

    useEffect(() => {
        const formStatusErrors = validateStatusForm();
        const isAnyFieldEmpty = Object.keys(statusForm).some((key) => !statusForm[key]);
        const hasErrors = Object.keys(formStatusErrors).some((key) => !!formStatusErrors[key]);

        setIsStatusFormInvalid(isAnyFieldEmpty || hasErrors);
    }, [statusForm]);


    // delete employee
    const handleDeleteEmployee = async () => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (!storedToken) {
                console.error('Authentication token not found.');
                return;
            }
            const response = await axios.delete(
                `${baseurl}/api/employee/delete/${userDetail._id}/${employeeId._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                }
            );
            if (response && response.data && response.data.success) {
                setShowDeleteSuccessModal(true);
                setShowDeleteErrorModal(false);
                fetchData();
            }
        } catch (error) {
            console.error('Error delteting empoloyee :', error);
            if (error.response && error.response.data && error.response.data.message) {
                setDeleteErrorMsg(error.response.data.message);
                setShowDeleteErrorModal(true);
                setShowDeleteSuccessModal(false);
            } else {
                setDeleteErrorMsg('An error occurred. Please try again.');
                setShowDeleteErrorModal(true);
                setShowDeleteSuccessModal(false);
            }
        }
    }

    // info of employee
    const [employeeInfo, setEmployeeInfo] = useState()

    const handleInfoEmployee = async (item) => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (!storedToken) {
                console.error('Authentication token not found.');
                return;
            }
            const response = await axios.get(
                `${baseurl}/api/employee/${userDetail._id}/${item._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                }
            );
            if (response && response.data && response.data.success) {
                setEmployeeInfo(response.data.employee)
                setShowInfoSuccessModal(true);
                setShowInfoErrorModal(false);
                fetchData();
            }
        } catch (error) {
            console.error('Error delteting empoloyee :', error);
            if (error.response && error.response.data && error.response.data.message) {
                setInfoErrorMsg(error.response.data.message);
                setShowInfoErrorModal(true);
                setShowInfoSuccessModal(false);
            } else {
                setInfoErrorMsg('An error occurred. Please try again.');
                setShowInfoErrorModal(true);
                setShowInfoSuccessModal(false);
            }
        }
    }

    return (

        <>
            {loading && <Loading />}
            {!loading && (
                <div>
                    {filterData?.length === 0 ? (
                        <div className='text-center'>No data found</div>
                    ) : (
                        filterData?.map((item) => (
                            <div key={item._id} className={`row align-items-center rounded-3 bg-white px-5 mb-2 ${style.employeelist}`}>
                                <div className="col-3">
                                    <div>
                                        <p className='fw-bold mb-0'>{item.firstname} {item.lastname}</p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div>
                                        <p className='fw-bold mb-0'>{item.email}</p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div>
                                        <p className='fw-bold mb-0'>{item.phone}</p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div>
                                        <p className='fw-bold mb-0'>{item.address}</p>
                                    </div>
                                </div>
                                <div className="col-3 d-flex align-items-center justify-content-between">
                                    <div >
                                        <button className={item.activeEmployeeStatus === 'Active' ? `${style.activeStatus}` : `${style.inactiveStatus}`}>{item.activeEmployeeStatus}</button>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                            {/* update location */}
                                            <div className="btn-group dropstart">
                                                <button type="button" className={`${style.dropbtn}`} data-bs-toggle="dropdown" aria-expanded="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="4" viewBox="0 0 14 4" fill="none">
                                                        <path d="M13.4874 0.512567C12.804 -0.170848 11.696 -0.170848 11.0126 0.512567C10.3291 1.19598 10.3291 2.30402 11.0126 2.98744C11.696 3.67085 12.804 3.67085 13.4874 2.98744C14.1709 2.30405 14.1709 1.19601 13.4874 0.512567Z" fill="#B3B3BF" />
                                                        <path d="M8.23744 0.512567C7.55402 -0.170848 6.44598 -0.170848 5.76257 0.512567C5.07915 1.19598 5.07915 2.30402 5.76257 2.98744C6.44598 3.67085 7.55402 3.67085 8.23744 2.98744C8.92085 2.30405 8.92085 1.19601 8.23744 0.512567Z" fill="#B3B3BF" />
                                                        <path d="M2.98744 0.512567C2.30402 -0.170848 1.19598 -0.170848 0.512564 0.512567C-0.170852 1.19598 -0.170852 2.30402 0.512564 2.98744C1.19598 3.67085 2.30402 3.67085 2.98744 2.98744C3.67085 2.30405 3.67085 1.19601 2.98744 0.512567Z" fill="#B3B3BF" />
                                                    </svg>
                                                </button>
                                                <ul className={`dropdown-menu ${style.dropmenu}`}>
                                                    <li>
                                                        <button
                                                            data-bs-toggle="modal"
                                                            href="#editemployee"
                                                            type="submit"
                                                            className={`${style.editBtn} m-1 ms-0`}
                                                            onClick={() => {
                                                                if (item) {
                                                                    handleEditClick(item);
                                                                }
                                                            }}
                                                        >
                                                            <span className='me-2'><GrFormEdit /></span>Edit
                                                        </button>
                                                    </li>

                                                    <li >
                                                        <button
                                                            data-bs-toggle="modal"
                                                            href='#deletemodal'
                                                            type="submit"
                                                            className={`${style.deleteBtn} m-1 ms-0`}
                                                            onClick={() => {
                                                                if (item) {
                                                                    setEmplyoeeId(item);
                                                                }
                                                            }}
                                                        >
                                                            <span><RiDeleteBinLine className='me-2' /></span>Remove
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            data-bs-toggle="modal"
                                                            href='#statusmodal'
                                                            type="submit"
                                                            className={`${style.statusBtn} m-1 ms-0`}
                                                            onClick={() => {
                                                                if (item) {
                                                                    handleEditStatusClick(item);
                                                                }
                                                            }}
                                                        >
                                                            <span className='me-2'><RxSwitch /></span>Change Status
                                                        </button>
                                                    </li>
                                                    <li >
                                                        <button
                                                            type="submit"
                                                            className={`${style.addBtn} m-1 ms-0`}
                                                            onClick={() => {
                                                                if (item) {
                                                                    handleInfoEmployee(item);
                                                                }
                                                            }}
                                                        >
                                                            <span ><IoMdAddCircleOutline className='me-2' /></span>Info
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="modal fade" id="editemployee" aria-hidden="true" aria-labelledby="editemployee" tabIndex="-1">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="editemployee">Edit Employee</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body" >
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <Form>
                                                                        <div className='row'>
                                                                            <div className="col-6 pe-2">
                                                                                <Form.Group controlId="firstname" className={`${style.formgroup}`}>
                                                                                    <Form.Label className={`${style.label}`}>First Name<span className='text-danger'>*</span></Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        placeholder='Enter first name here'
                                                                                        className={`mb-3`}
                                                                                        value={form.firstname}
                                                                                        F onChange={(e) => setField("firstname", e.target.value)}
                                                                                        isInvalid={!!errors.firstname}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">
                                                                                        {errors.firstname && <div className="text-danger">{errors.firstname}</div>}
                                                                                    </Form.Control.Feedback>
                                                                                </Form.Group>
                                                                            </div>
                                                                            <div className="col-6 ps-2">
                                                                                <Form.Group controlId='lastname' className={`${style.formgroup}`}>
                                                                                    <Form.Label className={`${style.label}`}>Lastname<span className='text-danger'>*</span></Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        placeholder='Enter last name here'
                                                                                        value={form.lastname}
                                                                                        onChange={(e) => setField("lastname", e.target.value)}
                                                                                        isInvalid={!!errors.lastname}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">
                                                                                        {errors.lastname && <div className="text-danger">{errors.lastname}</div>}
                                                                                    </Form.Control.Feedback>
                                                                                </Form.Group>
                                                                            </div>
                                                                        </div>
                                                                        <Form.Group controlId='email' className={`${style.formgroup}`}>
                                                                            <Form.Label className={`${style.label}`}>Email Address<span className='text-danger'>*</span></Form.Label>
                                                                            <Form.Control
                                                                                type="email"
                                                                                placeholder='Enter email address here'
                                                                                value={form.email}
                                                                                onChange={(e) => setField("email", e.target.value)}
                                                                                isInvalid={!!errors.email}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {errors.email && <div className="text-danger">{errors.email}</div>}
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                        <Form.Group controlId='phone' className={`${style.formgroup}`}>
                                                                            <Form.Label className={`${style.label}`}>Mobile Number<span className='text-danger'>*</span></Form.Label>
                                                                            <Form.Control
                                                                                type="tel"
                                                                                placeholder='Enter mobile number here'

                                                                                value={form.phone}
                                                                                onChange={(e) => setField("phone", e.target.value)}
                                                                                isInvalid={!!errors.phone}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                                                            </Form.Control.Feedback>

                                                                        </Form.Group>
                                                                        <Form.Group controlId='address' className={`${style.formgroup}`}>
                                                                            <Form.Label className={`${style.label}`}>Address<span className='text-danger '>*</span></Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder='Enter address here'
                                                                                className={` mb-3 w-100`}
                                                                                value={form.address}
                                                                                onChange={(e) => setField("address", e.target.value)}
                                                                                isInvalid={!!errors.address}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {errors.address && <div className="text-danger">{errors.address}</div>}
                                                                            </Form.Control.Feedback>

                                                                        </Form.Group>

                                                                        <Form.Group controlId='password'>
                                                                            <Form.Label className={`${style.label}`}>Password<span className='text-danger'>*</span></Form.Label>
                                                                            <Form.Control
                                                                                type="password"
                                                                                placeholder='Enter your password here'

                                                                                value={form.password}
                                                                                onChange={(e) => setField("password", e.target.value)}
                                                                                isInvalid={!!errors.password}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {errors.password && <div className="text-danger">{errors.password}</div>}
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className={`${style.closebtn}`} data-bs-dismiss="modal">Close</button>
                                                                            <Button
                                                                                type="submit"
                                                                                className={`${style.createbtn} `}
                                                                                data-bs-dismiss={isFormInvalid ? "" : "modal"}
                                                                                onClick={(e) => {
                                                                                    handleEditEmployee(e);
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        </div>
                                                                    </Form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Modal
                                                show={showEditSuccessModal}
                                                onHide={() => setShowEditSuccessModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="text-center">
                                                        <img src={success} alt="success" />
                                                        <p className="fw-bold">Admin is Edited Successfully!</p>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowEditSuccessModal(false)}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                            <Modal
                                                show={showEditErrorModal}
                                                onHide={() => setShowEditErrorModal(false)}
                                                centered
                                                backdropClassName="custom-backdrop"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Error</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>{editErrorMsg}</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="primary" className={style.closebtn} onClick={() => setShowEditErrorModal(false)}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                        {/* change status modal */}
                                        <div className="modal fade" id="statusmodal" aria-hidden="true" aria-labelledby="statusmodal" tabIndex="-1">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="statusmodal">Change Status of Employee</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body" >
                                                        Are you sure do you want to Change Status of this Employee?
                                                        <Form>
                                                            <Form.Group controlId='activeEmployeeStatus' className='w-100 mb-4'>
                                                                <Form.Label className={`${style.label}`}>Select Status<span className='text-danger '>*</span></Form.Label>
                                                                <Form.Select
                                                                    className={`${style.selectbox}`}
                                                                    value={statusForm?.activeEmployeeStatus} // Set the value directly from form state
                                                                    onChange={(e) => setStatusField('activeEmployeeStatus', e.target.value)} // Update form state on change
                                                                    isInvalid={!!statusErrors.activeEmployeeStatus}
                                                                >
                                                                    <option value="">Select Status</option>
                                                                    {statusOptions.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {statusErrors.activeEmployeeStatus && <div className="text-danger">{statusErrors.activeEmployeeStatus}</div>}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <div className="modal-footer">
                                                                <button type="button" className={`${style.closebtn}`} data-bs-dismiss="modal">Close</button>
                                                                <Button
                                                                    type="submit"
                                                                    className={`${style.createbtn} `}
                                                                    data-bs-dismiss={isStatusFormInvalid ? "" : "modal"}
                                                                    onClick={(e) => {
                                                                        handleStatusEmployee(e);
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </div>

                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Modal show={showStatusSuccessModal}
                                            onHide={() => setShowStatusSuccessModal(false)}
                                            centered
                                            backdropClassName="custom-backdrop"
                                        >
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="text-center">
                                                    <img src={success} alt="success" />
                                                    <p className="fw-bold">You have successfully Changed Status of Location!</p>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" className={style.closebtn} onClick={() => setShowStatusSuccessModal(false)}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={showStatusErrorModal}
                                            onHide={() => setShowStatusErrorModal(false)}
                                            centered
                                            backdropClassName="custom-backdrop"
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>Error</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>{statusErrorMsg}</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" className={style.closebtn} onClick={() => setShowStatusErrorModal(false)}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        {/* // delete modal */}
                                        <div className="modal fade" id="deletemodal" aria-hidden="true" aria-labelledby="deletemodal" tabIndex="-1">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="deletemodal">Delete Employee</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body" >
                                                        Are you sure do you want to delete this Employee?
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className={`${style.closebtn}`} data-bs-dismiss="modal">Close</button>
                                                        <button type="button"
                                                            className={`${style.createbtn} `}
                                                            data-bs-dismiss="modal"
                                                            onClick={handleDeleteEmployee}
                                                        >
                                                            Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Modal show={showDeleteSuccessModal}
                                            onHide={() => setShowDeleteSuccessModal(false)}
                                            centered
                                            backdropClassName="custom-backdrop"
                                        >
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="text-center">
                                                    <img src={success} alt="success" />
                                                    <p className="fw-bold">You have successfully Delete Employee!</p>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" className={style.closebtn} onClick={() => setShowDeleteSuccessModal(false)}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={showDeleteErrorModal}
                                            onHide={() => setShowDeleteErrorModal(false)}
                                            centered
                                            backdropClassName="custom-backdrop"
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>Error</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>{deleteErrorMsg}</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" className={style.closebtn} onClick={() => setShowDeleteErrorModal(false)}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <Modal show={showInfoSuccessModal}
                                            onHide={() => setShowInfoSuccessModal(false)}
                                            centered
                                            backdropClassName="custom-backdrop"
                                        >
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='text-center gap-2'>
                                                    <h4 className='fw-bold mb-3'> Information of Employee</h4>
                                                    <div className='border-bottom border-info border-3'></div>
                                                </div>
                                                <div className="mt-4 d-flex flex-column gap-3 text-center align-items-center justify-content-center">
                                                    <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                        <h5>Firstname:</h5>
                                                        <h5>{employeeInfo?.firstname}</h5>
                                                    </div>
                                                    <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                        <h5>Lastname:</h5>
                                                        <h5>{employeeInfo?.lastname}</h5>
                                                    </div>
                                                    <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                        <h5>Email Address:</h5>
                                                        <h5>{employeeInfo?.email}</h5>
                                                    </div>
                                                    <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                        <h5>Mobile Number:</h5>
                                                        <h5>{employeeInfo?.phone}</h5>
                                                    </div>
                                                    <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                        <h5>Address:</h5>
                                                        <h5>{employeeInfo?.address}</h5>
                                                    </div>
                                                    <div className='d-flex gap-3 align-items-center justify-content-center'>
                                                        <h5>Status:</h5>
                                                        <h5>{employeeInfo?.activeEmployeeStatus}</h5>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button className={`${style.closebtn}`} onClick={() => setShowInfoSuccessModal(false)}>
                                                    Close
                                                </button>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={showInfoErrorModal}
                                            onHide={() => setShowInfoErrorModal(false)}
                                            centered
                                            backdropClassName="custom-backdrop"
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>Error</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>{infoErrorMsg}</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" className={style.closebtn} onClick={() => setShowInfoErrorModal(false)}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )
            }
            {
                emplyoee?.length > 9 && (
                    <div className="d-flex justify-content-end">
                        <div className='d-flex align-items-center me-3'>
                            <div className='me-1'>
                                Rows Per Page:
                            </div>
                            <div>
                                <Form.Select size="sm" value={n} onChange={handlePerPageChange}>
                                    {perPageOptions.map((option, idx) => (
                                        <option key={idx} value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <ReactPaginate
                            containerClassName={"pagination align-items-center mb-0"}
                            activeClassName={`${style.pagination}`}
                            pageClassName={"page-item px-2"}
                            onPageChange={handlePageChange}
                            breakLabel={<span className="break-label">...</span>}
                            pageCount={Math.ceil(emplyoee?.length / n)}
                            previousLabel={
                                <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                                    <AiFillLeftCircle />
                                </IconContext.Provider>
                            }
                            nextLabel={
                                <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                                    <AiFillRightCircle />
                                </IconContext.Provider>
                            }
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                        />
                    </div>
                )}
        </>
    )
}

export default EmployeeList
